<template>
	<div class="catalog">
		<div class="catalog__cards">
			<Card v-for="flat in list[0]" :key="flat.id" :flat="flat" :name="name"/>
			<div class="catalog__card"></div>
			<div class="catalog__card"></div>
		</div>
		<a target="_blank" href="https://www.pik.ru/search?projects=1&attribute=bath&utm_source=megamarket&utm_medium=display&utm_content=bath" class="catalog__btn" v-if="name == 'podborka_1'" @click="sendEvent('btn_all_vannie')">Смотреть все квартиры</a>
		<a target="_blank" href="https://www.pik.ru/search/finish/ready?projects=1&utm_source=megamarket&utm_medium=display&utm_content=finish" class="catalog__btn" v-if="name == 'podborka_2'" @click="sendEvent('btn_all_postroen')">Смотреть все квартиры</a>
		<a target="_blank" href="https://www.pik.ru/search?projects=1&areaFrom=90&utm_source=megamarket&utm_medium=display&utm_content=area" class="catalog__btn" v-if="name == 'podborka_3'" @click="sendEvent('btn_all_ploshad')">Смотреть все квартиры</a>
		<a target="_blank" href="https://www.pik.ru/search?projects=1&attribute=frenchBalcony&utm_source=megamarket&utm_medium=display&utm_content=frenchBalcony" class="catalog__btn" v-if="name == 'podborka_4'" @click="sendEvent('btn_all_balcon')">Смотреть все квартиры</a>
		<a target="_blank" href="https://www.pik.ru/search?projects=1&blocks=55,118,161,90,156,294&utm_source=megamarket&utm_medium=display&utm_content=playhub" class="catalog__btn" v-if="name == 'podborka_5'" @click="sendEvent('btn_all_ploshadki')">Смотреть все квартиры</a>
	</div>
</template>

<script>
  import Card from '@/components/Card'
  export default {
	name: "Catalog",
	props: {
		list: {
			type: Array,
		},
		name: {
			type: String,
		},
	},
	components: {
		Card,
	},
	computed: {
		region() {
			return this.$store.getters.region
		}
	},
	methods: {
	}
}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.catalog__card:empty
	margin-bottom: 0
.catalog
	margin-bottom: 64px
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		margin-bottom: 27px
	@media screen and (max-width: 1440px)
		width: 845px
	@media screen and (max-width: 1200px) and (min-width: 889px)
		width: 585px
	@media screen and (max-width: 889px) and (min-width: 760px)
		width: 825px
		margin-top: 25px
	@media screen and (max-width: 760px)
		width: 100%
		margin-top: vwm(25px)
	&__cards
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		@media screen and (max-width: 760px)
			width: calc( 100% + vwm(30px))
			margin-left: vwm(-15px)
			justify-content: space-evenly								
			
	&__btn
		display: flex
		width: 100%
		height: 48px
		padding: 8px 16px
		justify-content: center
		align-items: center
		gap: 4px
		flex-shrink: 0
		border-radius: 32px
		background: #F2F5F7
		color: #FC4C02
		text-align: center
		font-size: 14px
		font-style: normal
		font-weight: 600
		line-height: 20px
		letter-spacing: -0.28px
		cursor: pointer
		transition: all 0.5s
		text-decoration: none
		margin-top: -15px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			height: 53px
			padding: 9px 18px
			font-size: 16px
			line-height: 22px
		@media screen and (max-width: 760px)
			width: 100%
			padding: vwm(8px) vwm(16px)
			height: vwm(32px)
			font-size: vwm(12.284px)
			margin-top: vwm(-25px)
			border-radius: vwm(1000px)
		&:hover
			background: #E4EBF0
								
			
</style>