<template>
	<div class="container">
		<div class="nav">
			<div class="nav__bg" ref="bg"></div>
			<a class="nav__item" ref="all" :class="{'nav__item-active': active == 'all'}" @click="setCategory('all'); sendEvent('kat_vse')">Все квартиры</a>
			<a class="nav__item" ref="podborka_6" :class="{'nav__item-active': active == 'podborka_6'}" @click="setCategory('podborka_6'); sendEvent('kat_ploshadki')">Заселение в 2024 году</a>
			<a class="nav__item" ref="podborka_7" :class="{'nav__item-active': active == 'podborka_7'}" @click="setCategory('podborka_7'); sendEvent('kat_ploshadki')">У метро</a>
			<a class="nav__item" ref="podborka_2" :class="{'nav__item-active': active == 'podborka_2'}" @click="setCategory('podborka_2'); sendEvent('kat_postroen')">Дом построен, есть отделка</a>
			<a class="nav__item" ref="podborka_8" :class="{'nav__item-active': active == 'podborka_8'}" @click="setCategory('podborka_8'); sendEvent('kat_ploshadki')">Доступные квартиры</a>
			<a class="nav__item" ref="podborka_3" :class="{'nav__item-active': active == 'podborka_3'}" @click="setCategory('podborka_3'); sendEvent('kat_ploshad')">Большие квартиры 90м2+</a>
			<!-- <a class="nav__item" ref="podborka_1" :class="{'nav__item-active': active == 'podborka_1'}" @click="setCategory('podborka_1'); sendEvent('kat_vannie')">Две ванные комнаты</a> -->
			<!-- <a class="nav__item" ref="podborka_4" :class="{'nav__item-active': active == 'podborka_4'}" @click="setCategory('podborka_4'); sendEvent('kat_balcon')">Французский балкон</a>
			<a class="nav__item" ref="podborka_5" :class="{'nav__item-active': active == 'podborka_5'}" @click="setCategory('podborka_5'); sendEvent('kat_ploshadki')">Большие игровые площадки</a> -->
		</div>
	</div>
</template>

<script>
  export default {
	name: "Navigation",
	computed: {
		active() {
			return this.$store.getters.category;
		}
	},
	methods: {
		setCategory(category) {
			this.$store.dispatch('setCategory', category)
			this.updateMenuSize();
		},
		updateMenuSize() {
			setTimeout(() => {
				const bg = this.$refs.bg;
				const target = this.$refs[this.active];
				bg.style.width = `${target.offsetWidth}px`;
				bg.style.height = `${target.offsetHeight}px`;
				if (this.active !== 'all') {
					if (window.innerWidth < 999) {
						bg.style.transform = `translateX(${target.offsetLeft-20}px)`;
					} else {
						bg.style.transform = `translateX(${target.offsetLeft-7}px)`;
					}
				} else {
					if (window.innerWidth < 999) {
						bg.style.transform = `translateX(1vw)`;
					} else {
						bg.style.transform = `translateX(4px)`;
					}
				}
			}, 350)
		}
	},
	mounted() {
		this.updateMenuSize();
		window.addEventListener('resize', this.updateMenuSize);
	}
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.nav
	margin-top: 34px
	padding: 8px 6px
	display: flex
	border-radius: 24px
	background: #F2F5F7
	position: relative
	color: #8C959C
	transition: all 0.3s ease
	&::-webkit-scrollbar
		width: 0
		height: 0
	&__bg
		position: absolute
		bottom: 0
		height: 42px
		background-color: #fff
		transition: all 0.3s ease
		pointer-events: none
		border-radius: 16px
		top: 0
		bottom: 0
		margin: auto
		z-index: 1
	@media screen and (max-width: 4920px) and (min-width: 1728px)
		margin-top: 38px
		padding: 8px 6px
		border-radius: 24px
	@media screen and (max-width: 889px)
		overflow-x: auto
	@media screen and (max-width: 760px)
		padding: vwm(12px) vwm(8px) vwm(12px) vwm(16px)
		overflow-x: auto
		width: calc(100% + vwm(30px))
		margin-top: vwm(16px)
		margin-left: vwm(-15px)
		border-radius: 0
	&__item
		display: flex
		padding: 12px 16px
		align-items: center
		gap: 8px

		font-family: "SB Sans Text"
		font-size: 14px
		font-style: normal
		font-weight: 600
		line-height: 18px
		border-radius: 16px
		margin-right: 9px
		cursor: pointer
		position: relative
		z-index: 2
		transition: all 0.5s
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			padding: 15px 20px
			font-size: 15px
			line-height: 18px
			border-radius: 16px
			margin-right: 12px
		@media screen and (max-width: 1440px)
			padding: 12px 10px
		@media screen and (max-width: 889px)
			font-size: 14px
			white-space: nowrap
		@media screen and (max-width: 760px)
			font-size: vwm(12px)
			padding: vwm(13px) vwm(10px) vwm(12px) vwm(10px)
			line-height: normal
			border-radius: vwm(16px)
			white-space: nowrap
			margin-right: vwm(9px)

		// &:hover
		// 	color: #1C1F22
		// 	background: #FFF
		&-active
			color: #1C1F22

</style>