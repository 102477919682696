import axios from 'axios'

export default {
  state: {
    regions: null,
    loading: true,
    region: null,
    category: 'all',
    filters: null,
    prices: [0, 0],
    flats: [],
  },

  getters: {
    regions: state => state.regions,
    loading: state => state.loading,
    flats: state => state.flats,
    region: state => state.region,
    category: state => state.category,
    filters: state => state.filters,
    prices: state => state.prices,
  },

  actions: {
    async getRegions({ commit }) {
      let regions = await axios.get('/api/regions/');
      commit('SET_REGIONS', regions.data)
    },
    async getFlats({ commit, state }, region) {
      commit('SET_LOADING', true);
      let flats = await axios.post(`/api/flats/`, {region: region, "price_range": state.prices });
      commit('SET_FLATS', flats.data)
      commit('SET_LOADING', false);
    },
    async setRegion({ commit }, region) {
      commit('SET_REGION', region);
    },
    async setCategory({ commit }, category) {
      console.log(category)
      commit('SET_CATEGORY', category);
    },
    async getFilters({ commit }) {
      let filters = await axios.get(`/api/filters/`);
      commit('SET_FILTERS', filters.data);
    },
    async setPriceRange({ commit }, prices) {
      commit('SET_PRICE_RANGE', prices);
    },
  },

  mutations: {
    SET_PRICE_RANGE(state, prices) {
      state.prices = prices;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_FLATS(state, flats) {
      state.flats = flats;
    },
    SET_REGION(state, region) {
      state.region = region;
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
    SET_LOADING(state, status) {
      if (status == false) {
        setTimeout(() => {
          state.loading = status;
        }, 2000)
      } else {
        state.loading = status;
      }
    },
  },
};
