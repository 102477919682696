<template>
	<div class="container">
    <div class="gift gift--end">
      <div class="gift__top">
        <div class="gift__top-title"><h1>Розыгрыш миллиона<br><span>СберСпасибо</span></h1></div>
        <div class="gift__top-icon"><img src="../assets/img/sber-logo.svg" alt=""></div>
        <div class="gift__top-icon gift__top-icon--spasibo"><img src="../assets/img/spasibo.svg" alt=""></div>
        <div class="gift__main-icon-dresser gift__main-icon-dresser--end"><img src="../assets/img/dresser.svg" alt=""></div>
      </div>

      <!-- <div class="gift__main">
        <div class="gift__main-left">
          <div class="gift__main-banner"></div>
          <div class="gift__main-icon"><img src="../assets/img/lamp.svg" alt=""></div>
        </div>
        <div class="gift__main-rigth">
          <div class="gift__main-count">
            <div class="gift__main-1">Пройдите<br>тест</div>
            <div class="gift__main-2">Заполните<br>анкету</div>
            <div class="gift__main-3">Участвуйте<br>в розыгрыше</div>
          </div>
          <div class="gift__main-title">
            <h2>Получите промокод на скидку<br>при заполнении анкеты</h2>
            <div class="gift__main-icon-gift"><img src="../assets/img/gift-2.svg" alt=""></div>
            <div class="gift__main-icon-dresser"><img src="../assets/img/dresser.svg" alt=""></div>
            <a href="/rules.pdf" target="_blank">Полные правила конкурса</a>
          </div>
        </div>
      </div> -->
      
      <div class="gift__message">
        <div class="gift__message-title">Розыгрыш завершен!</div>
        <div class="gift__message-description">Итоги подведём до 01.02.2024,<br>с победителями свяжемся по e-mail</div>

        <!-- <div class="gift__message-description">
          <div class="gift__message-item"><strong>Поздравляем победителей:</strong></div>
          <div class="gift__message-item">Анна Иванова us****il@mail.ru</div>
          <div class="gift__message-item">Анна Иванова us****il@mail.ru</div>
          <div class="gift__message-item">Анна Иванова us****il@mail.ru</div>
          <div class="gift__message-item">Анна Иванова us****il@mail.ru</div>
          <div class="gift__message-item">Анна Иванова us****il@mail.ru</div>
          <div class="gift__message-item">Анна Иванова us****il@mail.ru</div>
        </div> -->

        <a class="gift__message-link" href="/rules.pdf" target="_blank">Полные правила конкурса</a>
      </div>
      <!-- <Test /> -->
      <TestEnd />
    </div>
	</div>
</template>

<script>
// import Test from '@/components/Test.vue'
import TestEnd from '@/components/TestEnd.vue'

  export default {
    name: "Shop",
    components: {
      // Test,
      TestEnd
    },
    computed: {

    }
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"
.gift
  width: 100%
  height: 1593px
  border-radius: 45px
  background: #FAFAFA
  padding-top: 68px
  margin-top: 48px
  @media screen and (max-width: 1200px)
    border-radius: 35.017px
    padding-top: 52.915px
    height: 1430px
  @media screen and (max-width: 889px)
    height: 1260px
  @media screen and (max-width: 760px)
    padding-top: vwm(39px)
    height: vwm(1181px)
    border-radius: vwm(8px)
  &--end
    height: 1720px
    @media screen and (max-width: 1200px)
      height: 1620px !important
    @media screen and (max-width: 889px)
      height: 1550px !important
    @media screen and (max-width: 760px)
      height: vwm(1000px) !important
  &__message
    margin-top: 64px
    display: block
    text-align: center
    @media screen and (max-width: 760px)
      margin-top: vwm(-2px)
    &-title
      color: #8654CC
      font-size: 40px
      font-weight: 600
      line-height: 48px
      @media screen and (max-width: 760px)
        font-size: vwm(19px)
        line-height: vwm(21px)
        font-weight: 600
    &-description
      margin-top: 24px
      color: #323232
      font-feature-settings: 'clig' off, 'liga' off
      font-size: 20px
      font-weight: 400
      line-height: 24px
      @media screen and (max-width: 760px)
        font-size: vwm(12px)
        line-height: vwm(16px)
        margin-top: vwm(20px)
    &-link
      display: block
      margin-top: 24px
      color: #323232
      font-size: 18px
      font-weight: 400
      line-height: 20.7px
      text-decoration: underline
      @media screen and (max-width: 760px)
        font-size: vwm(12px)
        line-height: vwm(14px)
        margin-top: vwm(10px)
    &-item
      margin-top: 6px
      font-weight: 400
      strong
        font-weight: 600
      @media screen and (max-width: 760px)
        margin-top: vwm(4px)
      &:first-child
        margin-bottom: 14px
        margin-top: 0px
        @media screen and (max-width: 760px)
          margin-bottom: vwm(12px)
  &__top
    display: flex
    justify-content: center
    position: relative
    @media screen and (max-width: 1440px)
      margin-left: 47px
    @media screen and (max-width: 1200px)
      margin-left: 26px
    @media screen and (max-width: 889px)
      margin-left: 30px
    @media screen and (max-width: 760px)
      margin-left: vwm(0px)
    &-title
      background-image: url(~@/assets/img/bg.svg)
      background-size: contain
      background-repeat: no-repeat
      display: flex
      justify-content: center
      width: 773px
      height: 219px
      @media screen and (max-width: 1200px)
        width: 590px
        height: 170px
      @media screen and (max-width: 889px)
        width: 546px
      @media screen and (max-width: 760px)
        width: vwm(230px)
        height: vwm(90px)
      h1
        margin-top: 28px
        font-size: 64px
        font-weight: 600
        line-height: 76.8px
        color: #FFF
        text-align: center
        //letter-spacing: -3.2px
        @media screen and (max-width: 1200px)
          margin-top: 23px
          font-size: 50px
          line-height: 60px
          //letter-spacing: -2.2px
        @media screen and (max-width: 889px)
          margin-top: 21px
          font-size: 46px
          line-height: 51px
        @media screen and (max-width: 760px)
          margin-top: vwm(12px)
          font-size: vwm(17px)
          line-height: vwm(20px)
          //letter-spacing: vwm(0px)
        span
          background: linear-gradient(90deg, #BF0 0.25%, #51FF97 51.47%, #1EF2F8 100.25%)
          background-clip: text
          -webkit-background-clip: text
          -webkit-text-fill-color: transparent
    &-icon
      position: absolute
      top: 129px
      left: 249px
      img
        width: 102.911px
        height: 107px
        @media screen and (max-width: 1200px)
          width: 79.911px
          height: auto
        @media screen and (max-width: 889px)
          width: 74.911px
        @media screen and (max-width: 760px)
          width: vwm(42px)
      @media screen and (max-width: 1440px)
        left: 80px
      @media screen and (max-width: 1200px)
        left: 73px
        top: 100px
      @media screen and (max-width: 889px)
        left: 65px
        top: 88px
      @media screen and (max-width: 760px)
        left: vwm(30px)
        top: vwm(7px)
      &--spasibo
        position: absolute
        top: 42px
        right: 266px
        left: auto
        img
          width: 97px
          height: 95px
          @media screen and (max-width: 1200px)
            width: 75px
            height: auto
          @media screen and (max-width: 889px)
            width: 70px
          @media screen and (max-width: 760px)
            width: vwm(50px)
        @media screen and (max-width: 1440px)
          right: 104px
        @media screen and (max-width: 1200px)
          right: 80px
          top: 31px
        @media screen and (max-width: 889px)
          right: 68px
          top: 25px
        @media screen and (max-width: 760px)
          right: vwm(25px)
          top: vwm(33px)
  &__main
    margin-top: 117px
    display: flex
    @media screen and (max-width: 1200px)
      margin-top: 91px
    @media screen and (max-width: 889px)
      margin-top: 69px
    @media screen and (max-width: 760px)
      flex-direction: column-reverse
      margin-top: vwm(-50px)
    &-left
      position: relative
    &-icon
      position: absolute
      top: 140px
      left: 308px
      img
        width: 282.692px
        height: 399.833px
        @media screen and (max-width: 1200px)
          width: 216.692px
          height: 337.833px
        @media screen and (max-width: 889px)
          width: 192.692px
          height: 305.833px
        @media screen and (max-width: 760px)
          width: vwm(174.692px)
          height: vwm(280.833px)
      @media screen and (max-width: 1440px)
        left: 34px
      @media screen and (max-width: 1200px)
        left: 27px
        top: 95px
      @media screen and (max-width: 889px)
        left: 34px
        top: 89px
      @media screen and (max-width: 760px)
        left: vwm(174px)
        top: vwm(143px)
    &-banner
      background-image: url(~@/assets/img/banner-gift.png)
      width: 527px
      height: 386px
      border-radius: 0px 20px 20px 0px
      background-size: cover
      background-position-x: 100%
      @media screen and (max-width: 1440px)
        width: 253px
      @media screen and (max-width: 1200px)
        width: 198px
        height: 301px
      @media screen and (max-width: 889px)
        width: 188px
        height: 279px
      @media screen and (max-width: 760px)
        width: vwm(328px)
        height: vwm(222px)
        display: flex
        margin: auto
        border-radius: vwm(20px)
        margin-top: vwm(38px)
    &-rigth
      margin-left: auto
    &-count
      display: flex
      color: #323232
      font-size: 28px
      font-weight: 500
      line-height: 32.2px
      @media screen and (max-width: 1200px)
        font-size: 22px
        line-height: 26.2px
      @media screen and (max-width: 889px)
        font-size: 21px
      @media screen and (max-width: 760px)
        font-size: vwm(15px)
        line-height: vwm(16px)

    &-1
      display: flex
      align-items: center
      margin-left: -10px
      margin-top: 17px
      &::before
        content: ''
        display: flex
        background-image: url(~@/assets/img/1.svg)
        background-size: cover
        background-repeat: no-repeat
        width: 155px
        height: 140.211px
        background-position-x: 40px
        background-position-y: -16px
        @media screen and (max-width: 1200px)
          width: 122px
          height: 109px
          background-position-x: 30px
        @media screen and (max-width: 760px)
          width: vwm(64px)
          height: vwm(48px)
          background-position-x: vwm(12px)
          background-position-y: vwm(-10px)

      @media screen and (max-width: 1440px)
        margin-left: -37px
      @media screen and (max-width: 1200px)
        margin-left: -19px
      @media screen and (max-width: 889px)
        margin-top: 12px
      @media screen and (max-width: 760px)
        margin-right: vwm(-12px)
        margin-left: vwm(5px)
    &-2
      display: flex
      align-items: center
      margin-left: -32px
      margin-top: 17px
      padding-left: 13px
      &::before
        content: ''
        display: flex
        background-image: url(~@/assets/img/2.svg)
        background-size: cover
        background-repeat: no-repeat
        width: 155px
        height: 140.211px
        background-position-x: 28px
        background-position-y: -16px
        @media screen and (max-width: 1200px)
          width: 122px
          height: 109px
          background-position-x: 30px
        @media screen and (max-width: 760px)
          width: vwm(64px)
          height: vwm(48px)
          background-position-x: vwm(12px)
          background-position-y: vwm(-10px)
      @media screen and (max-width: 1440px)
        margin-left: -33px
      @media screen and (max-width: 1200px)
        margin-left: -35px
      @media screen and (max-width: 889px)
        margin-top: 12px
        margin-left: -58px
      @media screen and (max-width: 760px)
        margin-right: vwm(-30px)
        margin-left: vwm(6px)
    &-3
      margin-top: 17px
      margin-left: -32px
      margin-right: 12px
      display: flex
      align-items: center
      &::before
        content: ''
        display: flex
        background-image: url(~@/assets/img/3.svg)
        background-size: cover
        background-repeat: no-repeat
        width: 155px
        height: 140.211px
        background-position-x: 25px
        background-position-y: -16px
        @media screen and (max-width: 1200px)
          width: 122px
          height: 109px
          background-position-x: 30px
        @media screen and (max-width: 760px)
          width: vwm(64px)
          height: vwm(48px)
          background-position-x: vwm(12px)
          background-position-y: vwm(-10px)
      @media screen and (max-width: 1440px)
        margin-left: -31px
        margin-right: 16px
      @media screen and (max-width: 889px)
        margin-top: 12px
        margin-left: -39px
        margin-right: 7px
      @media screen and (max-width: 760px)
        margin-left: vwm(-200px)
        margin-top: vwm(120px)
    &-title
      margin-top: 26px
      margin-right: -1px
      position: relative
      display: flex
      align-items: center
      justify-content: center
      margin-left: auto
      background-image: url(~@/assets/img/bg2.svg)
      background-size: cover
      background-repeat: no-repeat
      width: 531.169px
      height: 167.652px
      @media screen and (max-width: 1440px)
        margin-top: 28px
        margin-right: 0px
      @media screen and (max-width: 1200px)
        width: 421.246px
        height: 131.127px
        margin-top: 15px
        margin-right: -2px
      @media screen and (max-width: 889px)
        width: 387.246px
        height: 124.1px
        margin-top: 11px
        margin-right: -2px
      @media screen and (max-width: 760px)
        margin-left: vwm(80px)
        width: vwm(263px)
        height: vwm(85px)
        margin-top: vwm(27px)
      h2
        color: #FFF
        font-size: 28px
        font-weight: 750
        line-height: 33.6px
        transform: rotate(-1.485deg)
        margin-left: 62px
        margin-top: -10px
        //letter-spacing: -1.2px
        @media screen and (max-width: 1440px)
          margin-left: 53px
          margin-top: -14px
        @media screen and (max-width: 1200px)
          //letter-spacing: -1.5px
          font-size: 23px
          line-height: 26.6px
          margin-left: 33px
          margin-top: -8px
        @media screen and (max-width: 889px)
          font-size: 21px
          line-height: 21.6px
          margin-top: -14px
        @media screen and (max-width: 760px)
          margin-left: vwm(25px)
          font-size: vwm(14px)
          line-height: vwm(20.6px)
          margin-top: vwm(-6px)
      a
        cursor: pointer
        position: absolute
        top: 185px
        right: 26px
        color: #323232
        font-size: 18px
        font-weight: 400
        line-height: 20.7px
        text-decoration-line: underline
        //letter-spacing: -0.5px
        @media screen and (max-width: 1440px)
          top: 182px
          right: 29px
        @media screen and (max-width: 1200px)
          font-size: 13px
          line-height: 14.95px
          top: 143px
          right: 30px
        @media screen and (max-width: 889px)
          top: 130px
          right: 14px
        @media screen and (max-width: 760px)
          display: none



    &-icon-gift
      left: -165px
      top: -65px
      position: absolute
      @media screen and (max-width: 1200px)
        left: -133px
        top: -51px
      @media screen and (max-width: 889px)
        left: -119px
        top: -47px
      @media screen and (max-width: 760px)
        left: vwm(-90px)
        top: vwm(-36px)
      img
        width: 250px
        height: auto
        @media screen and (max-width: 1200px)
          width: 200px
        @media screen and (max-width: 889px)
          width: 181px
        @media screen and (max-width: 760px)
          width: vwm(134px)

    &-icon-dresser
      position: absolute
      bottom: -840px
      right: 318px
      &--end
        bottom: -1434px
        @media screen and (max-width: 1440px)
          bottom: -1434px !important
        @media screen and (max-width: 1200px)
          bottom: -1400px !important
        @media screen and (max-width: 889px)
          bottom: -1329px !important
        @media screen and (max-width: 760px)
          bottom: vwm(-872px) !important
          right: vwm(1px) !important
      img
        width: 567px
        height: auto
        @media screen and (max-width: 1200px)
          width: 447px
        @media screen and (max-width: 760px)
          width: vwm(359px)
          z-index: 150
      @media screen and (max-width: 1440px)
        bottom: -841px
        right: 175px
      @media screen and (max-width: 1200px)
        bottom: -846px
        right: 92px
      @media screen and (max-width: 889px)
        bottom: -714px
      @media screen and (max-width: 760px)
        bottom: vwm(-824px)
        right: vwm(1px)
  &__bottom
    margin-top: 100px
    display: flex
    position: relative
    @media screen and (max-width: 1200px)
      margin-top: 78px
    @media screen and (max-width: 889px)
      margin-top: 71px
    @media screen and (max-width: 760px)
      margin-top: vwm(37px)
    &--end
      margin-top: 48px !important
      @media screen and (max-width: 760px)
        margin-top: vwm(30px) !important
    &-vase
      position: absolute
      top: 133px
      left: 119px
      img
        width: 260px
        height: auto
        @media screen and (max-width: 1200px)
          width: 203px
      @media screen and (max-width: 1440px)
        left: -23px
      @media screen and (max-width: 1200px)
        left: -19px
        top: 106px
      @media screen and (max-width: 760px)
        left: vwm(-49px)
    &-flower
      position: absolute
      top: -23px
      right: 0px
      background-image: url(~@/assets/img/flower.svg)
      width: 276px
      height: 398px
      background-repeat: no-repeat
      background-size: cover
      @media screen and (max-width: 1729px)
        right: -1px
      @media screen and (max-width: 1440px)
        top: -13px
        right: 0px
        width: 128px
        height: 400px
      @media screen and (max-width: 1200px)
        top: -27px
        width: 102px
        height: 337px
      @media screen and (max-width: 889px)
        width: 114px
      @media screen and (max-width: 760px)
        z-index: 100
        width: vwm(158px)
        top: vwm(50px)
        height: vwm(90px)
      &--end
        top: 92px


  </style>