<template>
	<div class="filter">
		<div class="filer__item">
			<div class="filter__label">Цена, руб</div>
			<div id="slider"></div>
			<div class="filter__slider-content" v-show="rangeStart !== 0">
				<div class="filter__slider-field">
					<div class="filter__slider-prefix">от</div>
					<input type="text" class="filter__slider-label" v-model.lazy="rangeStart" v-number="number" @change="updateMin">
					<div class="filter__slider-sufix">₽</div>
				</div>
				<div class="filter__slider-field">
					<div class="filter__slider-prefix">до</div>
					<input type="text" class="filter__slider-label" v-model.lazy="rangeEnd" v-number="number" @change="updateMax">
					<div class="filter__slider-sufix">₽</div>
				</div>
			</div>
		</div>
		<div class="filter__item filter__select">
			<v-select v-if="regions" :searchable="false" :options="regions" v-model="region" label="name" @input="changeCity">
				<template #open-indicator="{ attributes }">
					<svg v-bind="attributes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g id="Arrow_down">
							<path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M5.29293 8.29286C5.68347 7.90235 6.31664 7.90238 6.70714 8.29293L11.9997 13.586L17.2931 8.29572C17.6837 7.90531 18.3169 7.90549 18.7073 8.29613C19.0977 8.68677 19.0975 9.31994 18.7069 9.71035L12.7064 15.7074C12.3158 16.0977 11.6828 16.0976 11.2923 15.7071L5.29286 9.70707C4.90235 9.31653 4.90238 8.68336 5.29293 8.29286Z" fill="#8654CC"/>
						</g>
					</svg>
				</template>
			</v-select>
		</div>

		<div class="filter__btn" @click="reset">Очистить фильтры</div>
	</div>
</template>

<script>
	import noUiSlider from 'nouislider';
	import 'nouislider/dist/nouislider.css';
	export default {
		name: "Filters",
		data() {
			return {
				slider: null,
				rangeStart: 0,
				rangeEnd: 0,
				region: null,
				number: {
					decimal: '.',
					separator: ' ',
					precision: 0,
					min: 0,
					max: 0,
					masked: true,
				},
			}
		},
		computed: {
			regions() {
				return this.$store.getters.regions;
			},
			filters() {
				return this.$store.getters.filters;
			},
			category() {
				return this.$store.getters.category;
			},
		},
		methods: {
			changeCity() {
				this.loadFlats(false);
				if (this.region.id == 14) {
					this.sendEvent('filter_moskva')
				}
				if (this.region.id == 1) {
					this.sendEvent('filter_piter')
				}
				// if (this.region.id == 7) {
				// 	this.sendEvent('filter_ekater')
				// }
				// if (this.region.id == 6) {
				// 	this.sendEvent('filter_novgorod')
				// }
				// if (this.region.id == 8) {
				// 	this.sendEvent('filter_kazan')
				// }
				// if (this.region.id == 9) {
				// 	this.sendEvent('filter_vladi')
				// }
				// if (this.region.id == 12) {
				// 	this.sendEvent('filter_habarovsk')
				// }
				// if (this.region.id == 11) {
				// 	this.sendEvent('filter_blago')
				// }
				// if (this.region.id == 10) {
				// 	this.sendEvent('filter_saha')
				// }
				// // if (this.region.id == 9) {
				// // 	this.sendEvent('filter_yaros')
				// // }
				// if (this.region.id == 2) {
				// 	this.sendEvent('filter_yaros')
				// }
				// if (this.region.id == 5) {
				// 	this.sendEvent('filter_novo')
				// }
				// if (this.region.id == 4) {
				// 	this.sendEvent('filter_obninsk')
				// }
				// if (this.region.id == 13) {
				// 	this.sendEvent('filter_moskva')
				// }
			},
			updateMax() {
				if (this.rangeEnd && this.rangeEnd.length > 2) {
					let s = this.rangeStart
					let en = this.rangeEnd.replace(/ /g, '');
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				} else {
					let id = this.region.id;
					let category = this.category;
					let prices = this.filters.prices[id][category];
					let s = Math.round(prices[0]);
					let en = Math.round(prices[1]);
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.rangeStart = start;
					this.rangeEnd = end;
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				}
				this.loadFlats(false);
				this.sendEvent('filter_cena');
			},
			updateMin() {
				if (this.rangeStart && this.rangeStart.length > 2) {
					let s = this.rangeStart.replace(/ /g, '');
					let en = this.rangeEnd;
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				} else {
					let id = this.region.id;
					let category = this.category;
					let prices = this.filters.prices[id][category];
					let s = Math.round(prices[0]);
					let en = Math.round(prices[1]);
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.rangeStart = start;
					this.rangeEnd = end;
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				}
				this.loadFlats(false);
				this.sendEvent('filter_cena');
			},
			loadFlats(is_first_run = false) {
				let id = this.region.id;
				let category = this.category;
				if (is_first_run == true) {
					let prices = this.filters.prices[id][category];
					this.$store.dispatch('setPriceRange', prices);
					let start = Math.round(prices[0]);
					let end = Math.round(prices[1]);
					this.number.min = 0;
					this.number.max = end;
					this.slider.updateOptions({
						'start': [start, end],
						range: {
							'min': Math.round(prices[0]),
							'max': Math.round(prices[1])
						}
					}, true)
				}
				this.$store.dispatch('getFlats', this.region.id);
				this.$store.dispatch('setRegion', this.region);
			},
			reset() {
				let id = this.region.id;
				let category = this.category;
				let prices = this.filters.prices[id][category];
				this.$store.dispatch('setPriceRange', prices);
				let start = Math.round(prices[0]);
				let end = Math.round(prices[1]);
				this.slider.updateOptions({
					'start': [start, end],
					range: {
						'min': Math.round(prices[0]),
						'max': Math.round(prices[1])
					}
				}, true)
				this.$store.dispatch('getFlats', this.region.id);
				this.$store.dispatch('setRegion', this.region);
			},
			formatNumber(number) {
				return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			},
		},
		mounted() {
			this.$store.dispatch('getRegions').then(() => {
				this.region = this.regions[0]; // Москва по умолчанию
				this.$store.dispatch('setRegion', this.region);
				this.$store.dispatch('getFilters').then(() => {
					this.loadFlats(true);
				})
			})
			this.$nextTick(() => {
				this.slider = noUiSlider.create(document.getElementById('slider'), {
					start: [this.rangeStart, this.rangeEnd],
					connect: true,
					range: {
						'min': Math.round(this.rangeStart),
						'max': Math.round(this.rangeEnd)
					}
				});
				this.slider.on('update', (values) => {
					let start = Math.round(values[0]);
					let end = Math.round(values[1]);
					this.$store.dispatch('setPriceRange', [start, end]);
					this.rangeStart = start;
					this.rangeEnd = end;
				});
				this.slider.on('change', () => {
					this.loadFlats();
					this.sendEvent('filter_cena');
				});
			})
		},
		watch: {
			category(val) {
				let id = this.region.id;
				let category = val;
				let prices = this.filters.prices[id][category];
				let start = Math.round(prices[0]);
				let end = Math.round(prices[1]);
				this.$store.dispatch('setPriceRange', [start, end]);
				this.slider.updateOptions({
					range: {
						'min': start,
						'max': end,
					},
					'start': [start, end],
				}, true)
			}
		}
	}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.filter
	width: 278px
	max-width: 278px
	height: 253px
	position: sticky
	top: 15px
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		width: 312px
		max-width: 312px
	@media screen and (max-width: 1440px)
		width: 282px
		max-width: 282px
	@media screen and (max-width: 889px)
		display: none !important
	@media screen and (max-width: 760px)
		display: none !important
	&__label
		color: #1C1F22
		font-family: "SB Sans Text"
		font-size: 14px
		font-style: normal
		font-weight: 600
		line-height: 20px
		letter-spacing: -0.28px
		margin-bottom: 25px
		@media screen and (max-width: 889px)
			margin-top: 5px
		// 	font-size: 16px
		// 	line-height: 16px
		// 	font-weight: 600
		@media screen and (max-width: 760px)
			font-size: vwm(14px)
			line-height: vwm(16px)
			font-weight: 600
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			line-height: 20px
			letter-spacing: -0.28px
			margin-bottom: 30px
			font-size: 15px
	&__slider
		&-label
			color: #1C1F22
			font-size: 14px
			font-weight: 400
			line-height: 18px
			// @media screen and (max-width: 889px)
			// 	font-size: vwm(7px)
			@media screen and (max-width: 760px)
				font-size: vwm(14px)
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				line-height: 18px
				font-size: 16px
		&-field
			position: relative
			width: 140px
			&:last-child
				margin-left: 9px
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					margin-left: 20px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				width: 160px
			@media screen and (max-width: 889px)
				width: 100%
		&-prefix
			position: absolute
			left: 8px
			top: 8px
			color: #8C959C
			font-size: 12px
			font-style: normal
			font-weight: 400
			line-height: 16px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				left: 8px
				top: 11px
				line-height: 16px
			@media screen and (max-width: 760px)
				font-size: vwm(11px)
				top: vwm(14px)
				left: vwm(10px)

		&-sufix
			position: absolute
			right: 8px
			top: 8px
			color: #8C959C
			font-size: 12px
			font-style: normal
			font-weight: 400
			line-height: 16px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				left: 130px
				top: 11px
				line-height: 16px
			@media screen and (max-width: 760px)
				font-size: vwm(11px)
				top: vwm(14px)
				right: vwm(10px)
		&-content
			display: flex
			width: 100%
			margin-top: 25px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				margin-top: 28px
			input
				height: 33px
				border-radius: 8px
				border: 1px solid #D4DADF
				font-family: "SB Sans Text"
				font-style: normal
				font-weight: 400
				line-height: 18px
				outline: none
				width: 135px
				padding-left: 40px
				padding-left: 33px
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					height: 38px
					border-radius: 8px
					border: 1px solid #D4DADF
					line-height: 18px
					width: 145px
					padding-left: 30px
				@media screen and (max-width: 889px)
					width: vwm(132px)
					height: vwm(13px)
					border-radius: vwm(3px)
				@media screen and (max-width: 760px)
					width: vwm(155px)
					height: vwm(34px)
					border-radius: vwm(8px)

	&__select
		.v-select
			.vs__selected-options
				max-height: 1.6vw
			.vs__dropdown-option--selected
				background: none
				color: #5D3A8E
			.vs__dropdown-option
				padding-left: 14px
				padding-right: 14px
				height: 30px
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					padding-left: 14px
					padding-right: 14px
					height: 30px
			.vs__selecteds
				white-space: nowrap
				overflow: hidden
				text-overflow: ellipsis
			.vs__dropdown-option--highlight
				background-color: rgba(0,0,0,0.04)
				color: #5D3A8E
			.vs__dropdown-toggle
				width: 100%
				border-radius: 16px
				border: 1px solid #E2E6E9
				padding: 9px 12px 9px 9px
				background: #F2F5F7
				color: #1C1F22
				font-family: "SB Sans Text"
				font-size: 14px
				font-style: normal
				font-weight: 400
				line-height: vw(20px)
				letter-spacing: -0.28px
				margin-top: 32px
				outline: none
				cursor: pointer
				position: relative
				-webkit-appearance: none
				cursor: pointer
				@media screen and (max-width: 889px)
					margin-top: vwm(15px)
					padding: vwm(4px) vwm(12px) vwm(4px) vwm(9px)
					border-radius: vwm(8px)
				@media screen and (max-width: 760px)
					margin-top: vwm(30px)
					padding: vwm(8px) vwm(12px) vwm(8px) vwm(9px)
					border-radius: vwm(8px)
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					border-radius: 16px
					border: 1px solid #E2E6E9
					padding: 12px 12px 12px 9px
					line-height: 20px
					letter-spacing: -0.28px
					margin-top: 32px
			.vs__clear
				display: none
			// span
			// 	display: flex
			// 	position: absolute
			// 	background-repeat: no-repeat
			// 	background-image: url(~@/assets/img/arrow.svg)
			// 	background-size: vw(24px)

	&__btn
		display: flex
		height: 36px
		padding: 8px 16px
		justify-content: center
		align-items: center
		gap: 4px
		border-radius: 1000px
		background: #F2F5F7
		color: #677178
		text-align: center
		font-family: "SB Sans Text"
		font-size: 12px
		font-style: normal
		font-weight: 600
		line-height: 16px
		letter-spacing: -0.24px
		margin-top: 32px
		cursor: pointer
		transition: all 0.5s
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			padding: 11px 16px
			margin-top: 38px
		&:hover
			border: 1px solid #5D3A8E
			color: #5D3A8E
// slider style
.noUi-handle
	width: 28px !important
	height: 28px !important
	background-color:  #FFF
	filter: drop-shadow(0px 2px 4px rgba(8, 9, 10, 0.06))
	border-radius: 50%
	box-shadow: none
	right: -15px !important
	top: -12px !important
	cursor: pointer
	transition: all 0.5s
	&:hover
		width: 30px !important
		height: 30px !important
		top: -13px !important
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			width: 31px !important
			height: 31px !important
			top: -14px !important
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		width: 30px !important
		height: 30px !important
		background-color:  #FFF
		filter: drop-shadow(vw(0px) vw(2px) vw(4px) rgba(8, 9, 10, 0.06))
		border-radius: 50%
		box-shadow: none
		right: -15px !important
		top: -13px !important
.noUi-connect
	width: 100%
	height: 2px
	background-color: #9B38DC

.noUi-target
	width: 257px
	height: 2px
	margin-top: -1px
	margin-left: 11px
	background-color: #D4DADF
	border: none
	box-shadow: none
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		width: 290px
		height: 2px
		margin-top: -1px
.noUi-handle:before, .noUi-handle:after
	display: none !important
</style>