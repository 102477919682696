<template>
  <div id="app" class="wrapper">
    <div class="wrapper__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import "./sass/fonts.scss";
  @import "./sass/functions.sass";
  article,aside,details,figcaption,figure,footer,header,hgroup,hr,menu,nav,section{display:block}a,hr{padding:0}abbr,address,article,aside,audio,b,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,p,pre,q,samp,section,small,span,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,ul,var,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:0 0}ins,mark{background-color:#ff9;color:#000}body{line-height:1}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}a{margin:0;font-size:100%;vertical-align:baseline;background:0 0}ins{text-decoration:none}mark{font-style:italic;font-weight:700}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{height:1px;border:0;border-top:1px solid #ccc;margin:1em 0}input,select{vertical-align:middle}

  * {
    box-sizing: border-box;
  }
  
  body {
    background-color: #fff;
    overflow-x: hidden;
  }

  .container {
    width: 1590px;
    margin: auto;
    @media screen and (max-width: 1730px) {
      width: 1428px;
    }
    @media screen and (max-width: 1440px) {
      width: 1145px;
    }
    @media screen and (max-width: 1200px) {
      width: 890px;
    }
    @media screen and (max-width: 889px) {
      width: 825px;
    }
    @media screen and (max-width: 760px) {
      width: vwm(343px);
    }
  }
  .grecaptcha-badge { visibility: hidden; }
</style>