import axios from 'axios'
import Vue from 'vue'

export default {
  state: {
    form: null,
    errors: [],
  },

  getters: {
    form: state => state.form,
    errors: state => state.errors,
  },

  actions: {
    async submitForm({ commit }, payload) {
      try {
        let form = await axios.post('/form/create/', payload);
        commit('SET_FORM', form);
        return form;
      } catch(err) {
        let errors = err.response.data;
        commit('SET_ERRORS', errors);
        Object.entries(errors).forEach((e) => {
          Vue.$toast.open({
            position: 'bottom',
            type: 'error',
            message: `${e[0]}: ${e[1][0]}`,
          });
        });
        throw errors;
      }
    },
    removeError({ commit }, index) {
      commit('REMOVE_ERROR', index)
    },
  },

  mutations: {
    REMOVE_ERROR(state, index) {
      state.errors.splice(index, 1);
    },
    SET_FORM(state, form) {
      state.form = form;
    },
    SET_ERRORS(state, errors) {
      state.errors = [];
      Object.entries(errors).forEach((e) => {
        state.errors.push(e[0]); 
      }); 
    },
  },
};
