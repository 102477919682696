<template>
	<div class="hero">
		<div class="container">
			<div class="swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="hero__content hero__content--gift">
							<div class="hero__content-icon"><img src="../assets/img/spasibo.svg" alt=""></div>
							<div class="hero__content-icon hero__content-icon--sber"><img src="../assets/img/sber-logo.svg" alt=""></div>
							<div class="hero__content-icon hero__content-icon--gift"><img src="../assets/img/gift-2.svg" alt=""></div>
							<div class="hero__text">
								<div class="hero__subtitle letter-spacing">Розыгрыш<br>миллиона<br>СберСпасибо</div>
								<div class="hero__title hero__title--gift"><img src="../assets/img/pikwhite.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner7"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Хороший<br> выбор</div>
								<!-- <div class="hero__description">Квартиры для выгодной <br>покупки и счастливого <br>новоселья</div> -->
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Квартиры<br>с отделкой</div>
								<div class="hero__description">в построенных домах</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner2"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Квартиры<br>с двумя<br>санузлами</div>
								<div class="hero__description">удобно для большой семьи</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner3"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Семейная<br>подборка</div>
								<div class="hero__description">квартиры<br>с большой площадью</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner4"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Французский<br>балкон</div>
								<div class="hero__description">дом, где всегда<br>много света</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner5"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Играем<br>по-крупному</div>
								<div class="hero__description">лучшие детские площадки<br>в районах ПИК</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner6"></div>
						</div>
					</div>
				</div>
				<div class="swiper-pagination swiper-pagination-hero"></div>
			</div>
		</div>
		<!-- <div class="hero__gift"><img src="../assets/img/gift-2.svg" alt=""></div>? -->
		<div class="hero__call" @click="showModal = true; sendEvent('btn_callback')"></div>
		<div class="hero__modal" @click="modalClick" v-if="showModal == true">
			<div class="hero__iframe-wrapper">
				<iframe src="https://www.pik.ru/callback?source=megamarket" class="hero__iframe" frameborder="0"></iframe>
				<div class="hero__close" @click="showModal = false"></div>
			</div>
		</div>
	</div>
</template>

<script>
  import Swiper from 'swiper';
  import { Pagination, Autoplay } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';
  import 'swiper/swiper.css';

  export default {
	name: "Hero",
	data() {
		return {
			showModal: false,
			swiper: null,
		}
	},
	mounted() {
		this.swiper = new Swiper('.swiper', {
			loop: true,
			modules: [Pagination, Autoplay],
			speed: 900,
			pagination: {
				el: '.swiper-pagination-hero',
			},
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			}
		});
	},
	methods: {
		modalClick(event) {
		if (event.target.classList.contains('hero__modal') && this.showModal === true) {
			this.showModal = false;
		}}
	},
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"

</style>