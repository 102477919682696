<template>
    <div class="loader-wrapper">
        <span class="loader"></span>
    </div>
</template>

<script>
    export default {
        name: 'Loader'
    }
</script>

<style>
.loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    min-width: 100%;
}
.loader {
    width: 48px;
    height: 48px;
    display: flex;
    animation : rotate 2s linear infinite;
}
.loader:before,
.loader:after {
    content:"";
    flex:1;
    background:#9B38DC;
    animation: mvx 0.5s infinite linear alternate;
}
.loader:before {
    background: #FF3D00;
    animation-name:mvrx;
}

@keyframes rotate {
    100% {transform: rotate(360deg)}
}
@keyframes mvx {
    0% , 40% {transform: translateX(0px)}
    20% , 60% {transform: translateX(8px)}
    100% {transform: translateX(15px)}
}
@keyframes mvrx {
    0% , 40% {transform: translateX(0px)}
    20% , 60% {transform: translateX(-8px)}
    100% {transform: translateX(-15px)}
}    
</style>