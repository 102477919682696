<template>
  <div class="page">
    <Header />
    <HeroGift />
    <Navigation />
    <Gift />
    <Footter />
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import HeroGift from '@/components/HeroGift'
  import Navigation from '@/components/NavigationGift'
  import Gift from '@/components/Gift'
  import Footter from '@/components/Footter'
  export default {
    name: "Main",
    components: {
      Header,
      HeroGift,
      Navigation,
      Gift,
      Footter,
    }
  }
</script>
<style lang="sass">
body 
  font-family: "SB Sans Text"
</style>