<template>
	<div class="hero">
		<div class="container">
			<div class="swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Хороший<br> выбор</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Заселение<br>до конца года</div>
								<div class="hero__description">если не хочется долго ждать</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner8"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Квартиры<br>у метро</div>
								<div class="hero__description">до 15 минут пешком</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner9"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Квартиры<br>с отделкой</div>
								<div class="hero__description">в построенных домах</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner2"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Доступные <br>квартиры</div>
								<div class="hero__description">недорого и в пределах <br>МКАД и КАД</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner10"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Семейная<br>подборка</div>
								<div class="hero__description">квартиры<br>с большой площадью</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner4"></div>
						</div>
					</div>
					<!-- <div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Квартиры<br>с двумя<br>санузлами</div>
								<div class="hero__description">удобно для большой семьи</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner3"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Французский<br>балкон</div>
								<div class="hero__description">дом, где всегда<br>много света</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner5"></div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="hero__content">
							<div class="hero__text">
								<div class="hero__subtitle">Играем<br>по-крупному</div>
								<div class="hero__description">лучшие детские площадки<br>в районах ПИК</div>
								<div class="hero__title"><img src="../assets/img/PIK.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner6"></div>
						</div>
					</div> -->
					<!-- <div class="swiper-slide">
						<div class="hero__content hero__content--gift">
							<div class="hero__content-icon"><img src="../assets/img/spasibo.svg" alt=""></div>
							<div class="hero__content-icon hero__content-icon--sber"><img src="../assets/img/sber-logo.svg" alt=""></div>
							<div class="hero__content-icon hero__content-icon--gift"><img src="../assets/img/gift-2.svg" alt=""></div>
							<div class="hero__text">
								<div class="hero__subtitle letter-spacing">Розыгрыш<br>миллиона<br>СберСпасибо</div>
								<div class="hero__title hero__title--gift"><img src="../assets/img/pikwhite.svg" alt=""></div>
							</div>
							<div class="hero__img hero__img--banner7"></div>
						</div>
					</div> -->
				</div>
				<div class="swiper-pagination swiper-pagination-hero"></div>
			</div>
		</div>
		<!-- <router-link to="/gift" class="hero__gift"><img src="../assets/img/gift-2.svg" alt=""></router-link> -->
		<div class="hero__call" @click="showModal = true; sendEvent('btn_callback')"></div>
		<div class="hero__modal" @click="modalClick" v-if="showModal == true">
			<div class="hero__iframe-wrapper">
				<iframe src="https://www.pik.ru/callback?source=megamarket" class="hero__iframe" frameborder="0"></iframe>
				<div class="hero__close" @click="showModal = false"></div>
			</div>
		</div>
	</div>
</template>

<script>
  import Swiper from 'swiper';
  import { Pagination, Autoplay } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';
  import 'swiper/swiper.css';

  export default {
	name: "Hero",
	data() {
		return {
			showModal: false,
			swiper: null,
		}
	},
	mounted() {
		this.swiper = new Swiper('.swiper', {
			loop: true,
			modules: [Pagination, Autoplay],
			speed: 900,
			pagination: {
				el: '.swiper-pagination-hero',
			},
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			}
		});
	},
	methods: {
		modalClick(event) {
		if (event.target.classList.contains('hero__modal') && this.showModal === true) {
			this.showModal = false;
		}}
	},
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"
.letter-spacing
	letter-spacing: -2px
	@media screen and (max-width: 760px)
		letter-spacing: vwm(0px) !important
.hero
	background-color: #fff
	&__modal
		position: fixed
		top: 0
		left: 0
		z-index: 200
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.40)
		display: flex
		align-items: center
		justify-content: center
	&__iframe
		&-wrapper
			width: 521px
			height: 600px
			position: relative
			@media screen and (max-width: 760px)
				width: 343px
				height: 663px
			iframe
				width: 100%
				height: 100%
	&__close
		cursor: pointer
		position: absolute
		top: 20px
		right: 20px
		background-size: contain
		background-repeat: no-repeat
		background-image: url(~@/assets/img/close1.svg)
		width: 16px
		height: 16px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			top: 20px
			right: 20px
			width: 16px
			height: 16px
		@media screen and (max-width: 760px)
			width: vwm(12px)
			height: vwm(12px)
			top: vwm(20px)
			right: vwm(20px)
	&__content
		border-radius: 45px
		background: #FAFAFA
		display: flex
		position: relative
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			border-radius: 45px
		@media screen and (max-width: 760px)
			border-radius: vwm(12px)
		&--gift
			background: #8654CC
			position: relative
			@media screen and (max-width: 760px)
				border-radius: vwm(8px)
			.hero__img 
				@media screen and (max-width: 1200px)
					height: 420px
					width: 364px
				@media screen and (max-width: 760px)
					border-radius: vwm(12px)
					width: vwm(170px)
					height: vwm(122px)
			.hero__title--gift
				@media screen and (max-width: 1200px)
					margin-bottom: 43px
			.hero__title
				@media screen and (max-width: 1200px)
					width: 139px
					height: 49px
				@media screen and (max-width: 760px)
					margin-bottom: vwm(-14px)
				img
					@media screen and (max-width: 1200px)
						width: 147px
						height: 41px
					@media screen and (max-width: 889px)
						width: 129.388px
						height: 38.908px
					@media screen and (max-width: 760px)
						width: vwm(42px)
						height: vwm(32px)
			.hero__text 
				@media screen and (max-width: 1200px)
					padding-left: 38px
				@media screen and (max-width: 760px)
					padding-top: vwm(12px)
					padding-left: vwm(13px)
			.hero__subtitle
				color: #fff
				@media screen and (max-width: 1200px)
					font-size: 58px
					margin-top: 30px
					line-height: 59px
				@media screen and (max-width: 889px)
					font-size: 54px
					line-height: 53px
				@media screen and (max-width: 760px)
					margin-top: vwm(1px)
					font-size: vwm(17px)
					line-height: vwm(18px)
			.hero__content-icon
				position: absolute
				top: 87px
				left: 560px
				@media screen and (max-width: 1200px)
					top: 66px
					left: 433px
				@media screen and (max-width: 889px)
					left: 410px
				@media screen and (max-width: 760px)
					top: vwm(22px)
					left: vwm(140px)
				img
					width: 121.412px
					height: 118.909px
					@media screen and (max-width: 1200px)
						width: 98.412px
						height: auto
					@media screen and (max-width: 889px)
						width: 87.48px
					@media screen and (max-width: 760px)
						width: vwm(30px)
				&--sber
					top: 303px
					left: 315px
					@media screen and (max-width: 1200px)
						top: 233px
						left: 244px
					@media screen and (max-width: 889px)
						top: 216px
						left: 201px
					@media screen and (max-width: 760px)
						top: vwm(73px)
						left: vwm(81px)
					img
						width: 102.911px
						height: 107px
						@media screen and (max-width: 1200px)
							width: 81.911px
							height: auto
						@media screen and (max-width: 889px)
							width: 74.15px
						@media screen and (max-width: 760px)
							width: vwm(26px)
				&--gift
					top: 295px
					left: 478px
					@media screen and (max-width: 1200px)
						top: 229px
						left: 371px
					@media screen and (max-width: 889px)
						top: 215px
						left: 315px
					@media screen and (max-width: 760px)
						top: vwm(70px)
						left: vwm(122px)
					img
						width: auto
						height: 287px
						@media screen and (max-width: 1200px)
							width: auto
							height: 232px
						@media screen and (max-width: 889px)
							height: 210.803px
						@media screen and (max-width: 760px)
							height: vwm(73px)


	&__text
		padding-top: 8px
		padding-left: 53px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			padding-top: 8px
			padding-left: 55px
		@media screen and (max-width: 760px)
			padding-top: vwm(10px)
			padding-left: vwm(15px)
	&__title
		position: absolute
		bottom: 0
		margin-bottom: 48px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			margin-bottom: 54px
		@media screen and (max-width: 760px)
			margin-bottom: vwm(2px)
		img
			width: 182px
			height: 55px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				width: 210px
				height: 62px
			@media screen and (max-width: 1440px)
				width: 210px
				height: 62px
			@media screen and (max-width: 1200px)
				width: 168px
				height: 49px
			@media screen and (max-width: 889px)
				width: 123px
				height: 39px
			@media screen and (max-width: 760px)
				width: vwm(42px)
				height: vwm(32px)
		&--gift
			margin-bottom: 54px
			img
				@media screen and (max-width: 1440px)
					width: 182px
					height: 55px
	&__subtitle
		color: #323232
		font-family: "Graphik LC"
		font-size: 75px
		font-style: normal
		font-weight: 600
		line-height: 75px
		margin-top: 40px
		@media screen and (max-width: 1200px)
			font-size: 60px
			margin-top: 30px
			line-height: 60px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			font-size: 80px
			line-height: 80px
			margin-top: 43px
		&--main
			color: #FFFFFF
		@media screen and (max-width: 889px)
			margin-top: 30px
			font-size: 50px
			line-height: 55px
		@media screen and (max-width: 760px)
			margin-top: vwm(1px)
			font-size: vwm(17px)
			line-height: vwm(18px)
	&__description
		color: #8C8279
		font-family: "Graphik LC"
		font-size: 38px
		font-style: normal
		font-weight: 500
		line-height:  40px
		margin-top: 28px
		@media screen and (max-width: 1200px)
			font-size: 30px
			line-height: 30px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			line-height: 43px
			margin-top: 28px
			font-size: 38px
		&--main
			color: #FFFFFF
		&--main2
			color: #323232
			@media screen and (max-width: 760px)
				display: none
		&--main2mob
			display: none
			color: #323232
			@media screen and (max-width: 760px)
				display: block
		@media screen and (max-width: 889px)
			margin-top: 25px
			font-size: 20px
			line-height: 22px
		@media screen and (max-width: 760px)
			font-weight: 500
			font-size: vwm(9px)
			line-height: vwm(10px)
			margin-top: vwm(7px)
	&__img
		display: flex
		border-radius: 45px
		background-position: 35% 50%
		background-size: cover
		background-repeat: no-repeat
		background-image: url(~@/assets/img/bann.jpg)
		width: 715px
		height: 532px
		margin-left: auto
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			border-radius: 45px 45px 45px 45px
			width: 720px
			height: 532px
		&--banner2
			background-image: url(~@/assets/img/bann2.jpg)
			background-position: 20% 28%
			// background-size: 1000px
		&--banner3
			background-image: url(~@/assets/img/bann3.jpg)
			background-position: 75% 40%
			// background-size: 1000px
		&--banner4
			background-image: url(~@/assets/img/bann4.jpg)
			background-position: 90% 60%
			// background-size: 1000px
		&--banner5
			background-image: url(~@/assets/img/bann5.jpg)
			background-position: 80% 40%
			// background-size: 950px
		&--banner6
			background-image: url(~@/assets/img/bann6.jpg)
			background-position: 75% 85%
		&--banner7
			background-image: url(~@/assets/img/banner-gift.png)
			background-position: 47% 85%
			@media screen and (max-width: 1440px)
				background-position: 10% 85%
			@media screen and (max-width: 760px)
				background-image: url(~@/assets/img/banner-gift2.png)
				background-size: vwm(300px)
				background-position: 45% 99%
		&--banner8
			background-image: url(~@/assets/img/banner-6.jpg)
			background-position: 51% 85%
			background-size: 800px 584px
			@media screen and (max-width: 760px)
				background-position: 50% 84%
				background-size: vwm(186px) vwm(135px)
		&--banner9
			background-image: url(~@/assets/img/banner-7.jpg)
			background-position: 41% 18%
			background-size: 790px 539px
			@media screen and (max-width: 1000px)
				background-position: 75% 35%
			@media screen and (max-width: 760px)
				background-position: 24% 17%
				background-size: vwm(183px) vwm(125px)
		&--banner10
			background-image: url(~@/assets/img/banner-8.jpg)
			background-position: 65% 103%
			background-size: 920px 600px
			@media screen and (max-width: 760px)
				background-position: 63% 99%
				background-size: vwm(210px) vwm(142px)
		@media screen and (max-width: 1440px)
			width: 470px 
			height: 532px
		@media screen and (max-width: 1200px)
			height: 420px
			width: 420px
		@media screen and (max-width: 889px)
			width: 340px
			height: 383px
		@media screen and (max-width: 760px)
			border-radius: vwm(12px)
			width: vwm(172px)
			height: vwm(122px)
	&__call
		cursor: pointer
		position: fixed
		width: 80px
		height: 80px
		background-color: #FC4C02
		border-radius: 50%
		bottom: 34px
		right: 34px
		z-index: 200
		background-image: url(~@/assets/img/phone.svg)
		background-repeat: no-repeat
		background-position: 50%
		background-size: 35px
		@media screen and (max-width: 760px)
			display: none
	&__gift
		cursor: pointer
		position: fixed
		width: 60.678px
		height: 60.678px
		background: linear-gradient(136deg, #8654CC 15.73%, #4D1894 80.94%)
		border-radius: 50%
		bottom: 34px
		right: 34px
		z-index: 1000
		display: flex
		justify-content: center
		align-items: center
		img
			width: 45.34px
			height: 47.706px
		@media screen and (min-width: 760px)
			display: none
.swiper-pagination
	.swiper-pagination-bullet
		background-color: #F3F3F3
		opacity: 1
		&-active
			width: 28px
			background-color: #FC4C02
			border-radius: 9px

</style>