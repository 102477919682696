<template>
	<!-- <div class="banner">
		<div class="banner__content">
			<div class="banner__text">
				<div class="banner__text-title">Выгодная ипотека</div>
				<a class="banner__text-btn">Смотреть условия</a>
			</div>
			<div class="banner__img"></div>
		</div>
	</div> -->
	<!-- <a href="https://www.pik.ru/search?projects=1&currentBenefit=ipoteka-1-2&utm_source=megamarket&utm_medium=display&utm_content=mortgage" target="_blank" class="banner">
		<div class="banner__content">
			<div class="banner__text banner__text--centr">
				<div class="banner__text-title banner__text-title--red">Ипотека 1%</div>
				<a class="banner__text-title banner__text-title--dark">до 31 октября</a>
			</div>
		</div>
	</a> -->
	<!-- <a href="https://www.pik.ru/search?projects=1&currentBenefit=ipoteka-1-2&utm_source=megamarket&utm_medium=display&utm_content=mortgage" target="_blank"  @click="sendEvent('banner_ipoteka')" class="banner banner--new">
		<div class="banner__content banner__content--new">
			<div class="banner__text-title">Выгодные ставки<br>по ипотеке на pik.ru</div>
			<img src="../assets/img/PIK.svg" alt="">
		</div>
	</a> -->
	<!-- <a href="https://www.pik.ru/?utm_source=megamarket&utm_medium=display&utm_content=mortgage" target="_blank"  @click="sendEvent('banner_ipoteka')" class="banner banner--new banner--orange">
		<div class="banner__content banner__content--dec">
			<div class="banner__text-title">Специальные цены</div>
			<div class="banner__text-button">
				<a class="banner__text-btn"><i></i> до 15 февраля</a>
			</div>
			<div class="banner__text-copyright desktop-only"><img src="@/assets/img/copyright_1.svg" alt=""></div>
			<div class="banner__text-copyright mobile-only"><img src="@/assets/img/copyright_2.svg" alt=""></div>
		</div>
	</a> -->
	<a href="https://www.pik.ru/?utm_source=megamarket&utm_medium=display&utm_content=mortgage" target="_blank"  @click="sendEvent('banner_ipoteka')" class="banner banner--new">
		<div class="banner__content banner__content--mart">
			<div class="banner__content-column">
				<div class="banner__content-column-text">
					от <span>21 500</span><br><p>рублей в месяц</p>
				</div>
				<div class="banner__text-copyright desktop-only"><img src="@/assets/img/copy3.svg" alt=""></div>
				<div class="banner__text-copyright mobile-only"><img src="@/assets/img/copy4.svg" alt=""></div>
			</div>
			<div class="banner__content-column banner__content-column--2">
				<div class="banner__content-column-text banner__content-column-text--2">
					Своя квартира<br>в Москве
				</div>
			</div>
			<img src="../assets/img/pikwhite.svg" alt="">
		</div>
	</a>
</template>

<script>
  export default {
	name: "Banner",
  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.banner
	border-radius: 24px
	background: #FAFAFA
	display: block
	text-decoration: none
	margin-top: 50px
	position: relative
	height: 296px
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		width: 1260px
		height: 330px
		border-radius: 24px
		margin-top: 57px
	@media screen and (max-width: 1730px) and (min-width: 1730px)
		width: 1081px !important
	@media screen and (max-width: 1440px)
		width: 845px
		height: 330px
	@media screen and (max-width: 1200px) 
		width: 585px
	@media screen and (max-width: 889px)
		width: 825px
	@media screen and (max-width: 760px)
		border-radius: vwm(16px)
		width: vwm(343px)
		height: vwm(122px)
		margin-top: vwm(32px)
	&--new
		width: 1130px
		// display: flex
		// align-items: center
		// justify-content: center
		// background-color: #FAFAFA
		@media screen and (min-width: 1920px)
			width: 1290px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			width: 1260px
		@media screen and (max-width: 1440px)
			width: 845px
		@media screen and (max-width: 1200px)
			width: 585px
		@media screen and (max-width: 889px)
			width: 825px
		@media screen and (max-width: 760px)
			width: vwm(343px)
	img
		position: absolute
		bottom: 20px
		right: 23px
		width: 104px
		height: 44.162px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			bottom: 20px
			right: 23px
			width: 104px
			height: 44.162px
		@media screen and (max-width: 760px)
			bottom: vwm(8px)
			right: vwm(10px)
			width: vwm(40px)
			height: vwm(25px)
	&__content
		display: flex
		align-items: center
		justify-content: center
		&--new
			display: block
			.banner__text-title
				font-size: 70px
				line-height: 75px
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					font-size: 78px
					line-height: 83px
				@media screen and (max-width: 1200px) and (min-width: 889px)
					font-size: 50px
				@media screen and (max-width: 760px)
					font-size: vwm(22px)
					line-height: vwm(25px)
		&--dec
			.banner__text-title
				font-size: 77px
				text-align: center
				line-height: 77px
				color: #323232
				@media screen and (max-width: 1200px) and (min-width: 889px)
					font-size: 50px
					line-height: 50px
				@media screen and (max-width: 760px)
					font-size: vwm(32px)
					line-height: vwm(32px)
				span
					color: #8C8279 !important
		&--mart
			display: flex
			width: 100%
			height: 100%
			.banner__content-column
				border-radius: 24px 0 0 24px
				width: 50%
				height: 100%
				display: flex
				align-items: center
				justify-content: center
				background-color: #FAFAFA
				position: relative
				.banner__text-copyright
					width: 90%
					display: flex
					margin: auto
					&.desktop-only
						@media screen and (max-width: 760px)
							display: none
					&.mobile-only
						display: none
						@media screen and (max-width: 760px)
							display: flex !important
							bottom: vwm(10px)
						img
							height: auto
							width: vwm(400px)
				@media screen and (max-width: 760px)
					border-radius: vwm(16px) 0 0 vwm(16px) 
				&--2
					border-radius: 0 24px 24px 0
					background-color: #FC4C02
					@media screen and (max-width: 760px)
						border-radius: 0 vwm(16px) vwm(16px) 0 
			.banner__content-column-text
				text-align: center
				color: #000000
				font-size: 20px
				font-weight: 600
				line-height: 20px
				font-family: Graphik LC
				margin-top: 18px
				@media screen and (max-width: 760px)
					font-size: vwm(8px)
					font-weight: 600
					line-height: vwm(8px)
					font-family: Graphik LC
					margin-top: vwm(-10px)
				span
					font-size: 65px
					letter-spacing: -1.2px
					margin-left: 4px
					@media screen and (max-width: 760px)
						font-size: vwm(28px)
						letter-spacing: vwm(-1.2px)
						margin-left: vwm(2px)
				p
					margin-top: 6px
					margin-left: 20px
					@media screen and (max-width: 760px)
						margin-top: vwm(2px)
						margin-left: vwm(20px)
				&--2
					margin-top: 10px
					font-family: Graphik LC
					font-size: 42px
					font-weight: 500
					line-height: 42px
					letter-spacing: -0.02em
					color: #FFFFFF
					@media screen and (max-width: 1200px)
						font-size: 32px
					@media screen and (max-width: 760px)
						font-size: vwm(18px)
						letter-spacing: vwm(-1.2px)
						line-height: vwm(18px)
						margin-top: vwm(-14px)

	&--orange
		background-color: #FC4C02 !important
		color: #fff !important
		.banner__content
			flex-direction: column
			padding-bottom: 40px
		.banner__text-btn
			background-color: #fff !important
			color: #FC4C02 !important
			font-size: 53.56px
			width: 520px
			height: 84px
			line-height: 84px
			border-radius: 100px
			display: flex
			margin: auto
			margin-top: 10px
			align-items: center
			white-space: nowrap
			line-height: 1
			@media screen and (max-width: 760px)
				width: vwm(193px)
				font-size: vwm(17.446px)
				height: vwm(32px)
				margin-top: vwm(10px)
			i
				display: block
				width: 26px
				height: 44px
				margin-left: 5px
				margin-right: 14px
				background-size: contain
				background-repeat: no-repeat
				background-position: 50% 50%
				background-image: url(../assets/img/icon_1.svg)
				@media screen and (max-width: 760px)
					min-width: vwm(8px)
					width: vwm(8px)
					height: vwm(14px)
					margin-left: vwm(2px)
					margin-right: vwm(4px)
		.banner__text-button
			display: block
			width: 100%
		.banner__text-title
			font-size: 77px
			text-align: center
			line-height: 77px
			color: #fff
			@media screen and (max-width: 1200px) and (min-width: 889px)
				font-size: 50px
				line-height: 50px
			@media screen and (max-width: 760px)
				font-size: vwm(25px)
				line-height: vwm(25px)
				padding-bottom: 0
			span
				color: #8C8279 !important
	&__text
		padding-top: vw(48px)
		padding-left: vw(72px)
		&--centr
			padding-top: vw(80px)
			margin: auto
			@media screen and (max-width: 4920px) and (min-width: 1728px)
				padding-top: 89px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			padding-top: 53px
			padding-left: 80px
		@media screen and (max-width: 760px)
			padding-top: vwm(40px)
			margin-left: auto
		&-copyright
			width: 728.38px
			bottom: 11px
			display: block
			position: absolute
			left: 0
			right: 0
			margin: auto
			&.desktop-only
				@media screen and (max-width: 760px)
					display: none
			&.mobile-only
				display: none
				@media screen and (max-width: 760px)
					display: block
			@media screen and (max-width: 1200px)
				width: 400px
			@media screen and (max-width: 760px)
				bottom: vwm(6px)
				width: vwm(250px)
				left: 0
				right: 0
				margin: auto
			img
				width: 100%
				position: static
		&-title
			color: #000
			font-family: "Graphik LC"
			font-size: vw(32px)
			font-style: normal
			font-weight: 600
			line-height: vw(38.4px)
			letter-spacing: vw(-0.64px)
			@media screen and (max-width: 760px)
				padding-bottom: vwm(20px)
			&--dark
				text-align: center
				color: #323232
				font-size: vw(65px)
				line-height: vw(66px)
				@media screen and (max-width: 4920px) and (min-width: 1728px)
					font-size: 72px
					line-height: 73px
			&--red
				text-align: center
				color: #FC4C02
				line-height: vw(66px)
				font-size: vw(65px)
				@media screen and (max-width: 4920px) and (min-width: 1728px)
					font-size: 73px
					line-height: 72px
			@media screen and (max-width: 4920px) and (min-width: 1728px)
				font-size: 36px
				line-height: 42px
				letter-spacing: -0.64px
			@media screen and (max-width: 760px)
				font-size: vwm(13px)
				&--red
					font-size: vwm(20px)
					line-height: vwm(20px)
				&--dark
					font-size: vwm(20px)
					line-height: vwm(26px)
		&-btn
			display: flex
			width: vw(275px)
			height: vw(36px)
			padding: vw(8px) vw(16px)
			justify-content: center
			align-items: center
			font-family: "SB Sans Text"
			gap: vw(4px)
			border-radius: vw(32px)
			background: #FC4C02
			color: #FFF
			text-align: center
			font-size: vw(14px)
			font-weight: 400
			line-height: vw(20px)
			letter-spacing: vw(0.3px)
			margin-top: vw(26px)
			transition: all 0.5s
			cursor: pointer
			@media screen and (max-width: 4920px) and (min-width: 1728px)
				width: 306px
				height: 40px
				padding: 9px 19px
				gap: 4px
				border-radius: 32px
				font-size: 16px
				line-height: 22px
				letter-spacing: 0.3px
				margin-top: 29px
			@media screen and (max-width: 760px)
				width: vwm(113px)
				height: vwm(15px)
				font-size: vwm(6px)
				padding: vwm(8px) vwm(16px)
				margin-top: vwm(15px)
				border-radius: vwm(1000px)
			&:hover
				background: #E44502
	&__img
		display: flex
		border-radius: vw(24px)
		background-size: vw(983px)
		background-repeat: no-repeat
		background-image: url(~@/assets/img/banner-4.jpg)
		width: vw(685px)
		height: vw(296px)
		margin-left: auto
		background-position: 32% 45%
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			border-radius: 24px
			background-size: 1096px
			width: 763px
			height: 330px
		@media screen and (max-width: 1440px)
			background-position: 15% 45%
			width: 400px
		@media screen and (max-width: 889px)
			width: vw(380px)
		@media screen and (max-width: 760px)
			height: vwm(122px)
			width: vwm(173px)
			background-size: vwm(410px)
			background-position: 17% 46%
			border-radius: vwm(10px) vwm(16px) vwm(16px) vwm(10px)
</style>