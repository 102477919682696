<template>
  <div class="page">
    <Header />
    <Hero />
    <Navigation />
    <Shop />
    <Footter />
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import Hero from '@/components/Hero'
  import Navigation from '@/components/Navigation'
  import Shop from '@/components/Shop'
  import Footter from '@/components/Footter'
  export default {
    name: "Main",
    components: {
      Header,
      Hero,
      Navigation,
      Shop,
      Footter,
    }
  }
</script>
<style lang="sass">
body 
  font-family: "SB Sans Text"
</style>