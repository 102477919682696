<template>
	<div class="container">
		<div class="nav">
			<a class="nav__item" ref="all" @click="setCategory('all'); sendEvent('kat_vse')">Все квартиры</a>
			<a class="nav__item" ref="podborka_2" @click="setCategory('podborka_2'); sendEvent('kat_postroen')">Дом построен, есть отделка</a>
			<a class="nav__item" ref="podborka_1" @click="setCategory('podborka_1'); sendEvent('kat_vannie')">Две ванные комнаты</a>
			<a class="nav__item" ref="podborka_3" @click="setCategory('podborka_3'); sendEvent('kat_ploshad')">Площадь 90+ м2</a>
			<a class="nav__item" ref="podborka_4" @click="setCategory('podborka_4'); sendEvent('kat_balcon')">Французский балкон</a>
			<a class="nav__item" ref="podborka_5" @click="setCategory('podborka_5'); sendEvent('kat_ploshadki')">Большие игровые площадки</a>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Navigation",
	computed: {
		active() {
			return this.$store.getters.category;
		}
	},
	methods: {
		setCategory(category) {
			this.$router.push(`/?category=${category}`);
			this.$store.dispatch('setCategory', category);
		},
	},
  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.nav
	margin-top: 34px
	padding: 8px 6px
	display: flex
	border-radius: 24px
	background: #F2F5F7
	position: relative
	&::-webkit-scrollbar
		width: 0
		height: 0
	&__bg
		position: absolute
		bottom: 0
		height: 42px
		background-color: #fff
		transition: all 0.3s ease
		pointer-events: none
		border-radius: 16px
		top: 0
		bottom: 0
		margin: auto
		z-index: 1
	@media screen and (max-width: 4920px) and (min-width: 1728px)
		margin-top: 38px
		padding: 8px 6px
		border-radius: 24px
	@media screen and (max-width: 889px)
		overflow-x: auto
	@media screen and (max-width: 760px)
		padding: vwm(12px) vwm(8px) vwm(12px) vwm(16px)
		overflow-x: auto
		width: calc(100% + vwm(30px))
		margin-top: vwm(16px)
		margin-left: vwm(-15px)
		border-radius: 0
	&__item
		display: flex
		padding: 12px 16px
		align-items: center
		gap: 8px
		color: #8C959C
		font-family: "SB Sans Text"
		font-size: 14px
		font-style: normal
		font-weight: 600
		line-height: 18px
		border-radius: 16px
		margin-right: 9px
		cursor: pointer
		position: relative
		z-index: 2
		transition: all 0.5s
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			padding: 15px 20px
			font-size: 15px
			line-height: 18px
			border-radius: 16px
			margin-right: 12px
		@media screen and (max-width: 1440px)
			padding: 12px 10px
		@media screen and (max-width: 889px)
			font-size: 14px
			white-space: nowrap
		@media screen and (max-width: 760px)
			font-size: vwm(12px)
			padding: vwm(13px) vwm(10px) vwm(12px) vwm(10px)
			line-height: normal
			border-radius: vwm(16px)
			white-space: nowrap
			margin-right: vwm(9px)

		// &:hover
		// 	color: #1C1F22
		// 	background: #FFF
		// &-active
		// 	color: #1C1F22
		// 	background: #FFF

</style>