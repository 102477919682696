var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[(_vm.title == 'podborka_2')?_c('div',{staticClass:"banner"},[_vm._m(0),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_1')?_c('div',{staticClass:"banner banner--2"},[_vm._m(1),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_3')?_c('div',{staticClass:"banner banner--3"},[_vm._m(2),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_4')?_c('div',{staticClass:"banner banner--4"},[_vm._m(3),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_5')?_c('div',{staticClass:"banner banner--5"},[_vm._m(4),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_6')?_c('div',{staticClass:"banner banner--6"},[_vm._m(5),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_7')?_c('div',{staticClass:"banner banner--7"},[_vm._m(6),_c('div',{staticClass:"banner__gradietn"})]):_vm._e(),(_vm.title == 'podborka_8')?_c('div',{staticClass:"banner banner--8"},[_vm._m(7),_c('div',{staticClass:"banner__gradietn"})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Квартиры"),_c('br'),_vm._v("с отделкой")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("в построенных домах")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Квартиры"),_c('br'),_vm._v("с двумя санузлами")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("удобно для большой семьи")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Семейная"),_c('br'),_vm._v("подборка")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("большие квартиры 90+ м2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Французский"),_c('br'),_vm._v("балкон")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("дом, где всегда много света")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Квартиры в проектах"),_c('br'),_vm._v("с PlayHub и LocalPlay")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("лучшие детские площадки в районах ПИК")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Заселение"),_c('br'),_vm._v("до конца года")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("если не хочется "),_c('br'),_vm._v("долго ждать")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Квартиры"),_c('br'),_vm._v("у метро")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("до 15 минут пешком")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__text"},[_c('div',{staticClass:"banner__text-title"},[_vm._v("Доступные"),_c('br'),_vm._v("квартиры")]),_c('div',{staticClass:"banner__text-subtitle"},[_vm._v("недорого и в пределах "),_c('br'),_vm._v("МКАД и КАД")])])
}]

export { render, staticRenderFns }