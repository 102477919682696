<template>
	<div class="catalog__card">
		<div class="catalog__card-img catalog__card-img--desk"><img :src="flat.image_2d" loading="lazy" alt="">
			<div class="catalog__card-hover"><img :src="flat.image_3d" loading="lazy" alt=""></div>
		</div>
		<div class="swiper swiper--mobile">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="catalog__card-img"><img :src="flat.image_2d" loading="lazy" alt=""></div>
				</div>
				<div class="swiper-slide">
					<div class="catalog__card-img"><img :src="flat.image_3d" loading="lazy"  alt=""></div>
				</div>
			</div>
			<div class="swiper-pagination swiper-pagination-m swiper-pagination--mobile"></div>
		</div>
		<div class="catalog__card-items">
			<div class="catalog__card-price">{{formatNumber(flat.price)}} ₽</div>
			<div class="catalog__card-item"><p>{{getLabel(flat.rooms)}} {{ flat.area }} м<sup>2</sup></p></div>
			<div class="catalog__card-item catalog__card-item--credit">{{formatNumber(flat.min_month_fee)}} ₽ в мес.<a href="javascript:void(0)" :class="`js-show-info-${flat.id}`" @click="showInfo = !showInfo">i</a></div>
			<template>
				<div class="modal-info" v-if="showInfo == true">
					<div class="modal-info__content">
						Предварительный расчёт. Точная полная стоимость кредита и итоговая процентная ставка по кредиту будут указаны в кредитном договоре и рассчитываются индивидуально.
						<br><br>Нажмите на «Подробнее» и воспользуйтесь ипотечным калькулятором на сайте pik.ru, чтобы подобрать подходящую программу ипотеки.
					</div>
					<div class="modal-info__close" @click="showInfo = false"><img src="../assets/img/close.svg" alt=""></div>
				</div>
			</template>
			<div class="catalog__card-item catalog__card-item--adress">{{ flat.building_name }}</div>
			<div class="catalog__card-item catalog__card-item--gray" v-if="flat.renovation">{{flat.renovation}}</div>
			<div class="catalog__card-item">г. {{region.name}}</div>
			<div class="catalog__card-item">Заселение в {{flat.ready_quarter}} кв. {{flat.built_year}}</div>
			<a :href="flat.url" class="catalog__card-btn" target="_blank" @click="setFlat(name)">Подробнее</a>
		</div>
	</div>
</template>

<script>
  import Swiper from 'swiper';
  import { Pagination } from 'swiper/modules';
  import 'swiper/swiper-bundle.min.css';
  export default {
	name: "Card",
	props: {
		flat: {
			type: Object
		},
		name: {
			type: String
		}
	},
	data() {
		return {
			showModal: false,
			showInfo: false,
		}
	},
	computed: {
		region() {
			return this.$store.getters.region
		}
	},
	mounted() {
		window.addEventListener('click', (e) => {
			if (!e.target.classList.contains('modal-info') && !e.target.classList.contains(`js-show-info-${this.flat.id}`)) {
				this.showInfo = false;
			}
		});
		new Swiper('.swiper', {
			loop: true,
			modules: [Pagination],
			pagination: {
				el: '.swiper-pagination-m',
				clickable: true,
			},
			on: {
				slideChange: function (sw) {
					sw.pagination.render();
					sw.pagination.update();
				},
			},
		});
	},
	methods: {
		setFlat(item) {
			if (item == 'podborka_1') {
				this.sendEvent('btn_card_vannie')
			}
			if (item == 'podborka_2') {
				this.sendEvent('btn_card_postroen')
			}
			if (item == 'podborka_3') {
				this.sendEvent('btn_card_ploshad')
			}
			if (item == 'podborka_4') {
				this.sendEvent('btn_card_balcon')
			}
			if (item == 'podborka_5') {
				this.sendEvent('btn_card_ploshadki')
			}
		},
		formatNumber(number) {
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		},
		getLabel(text) {
			if (text == '1') {return '1-комнатная'}
			if (text == '2') {return '2-комнатная'}
			if (text == '3') {return '3-комнатная'}
			if (text == '4') {return '4-комнатная'}
			if (text == 'студия') {return 'Cтудия'}
			return '';
		}
	}
}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.modal-info
	position: absolute
	padding: 14px
	width: 259px
	height: 130px
	z-index:  1000000
	border-radius: 8px
	background-color: rgba(217, 217, 217, 0.90)
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		padding: 15px
		width: 289px
		height: 130px
	@media screen and (max-width: 760px)
		padding: vwm(14px)
		width: vwm(259px)
		height: vwm(120px)
		border-radius: vwm(8px)
	&__content
		color: #1C1F22
		font-family: SB Sans Text
		font-size: 8px
		line-height: 12px
		letter-spacing: -0.16px
		position: relative
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			font-size: 9px
			line-height: 13px
		@media screen and (max-width: 760px)
			font-size: vwm(8px)
			line-height: vwm(12px)
			letter-spacing: vwm(-0.16px)
	&__close
		position: absolute
		width: 17px
		height: 17px
		background-color: #F7F7F5
		display: flex
		align-items: center
		justify-content: center
		border-radius: 50%
		top: -20px
		right: 0
		cursor: pointer
		z-index: 300
		&::before
			width: 200%
			height: 200%
			position: absolute
			content: ''
			display: block
			margin-left: -50%
		@media screen and (max-width: 760px)
			width: vwm(17px)
			height: vwm(17px)
			top: vwm(-20px)

.swiper
	&--mobile
		display: none
		@media screen and (max-width: 760px)
			display: flex
			width: vwm(172px)
			height: vwm(172px)
.catalog
	.swiper-pagination
		&--mobile
			text-align: end
			margin-top: 10px
			.swiper-pagination-bullet
				background-color: #BEC9D1
				opacity: 1
				width: 5px
				height: 5px
				margin: -11px var(--swiper-pagination-bullet-horizontal-gap, 10px -5px -5px) !important
				&:first-child
					margin-right: 3px
				&:last-child
					margin-right: 18px
				&-active
					width: 5px
					height: 5px
					background-color: #677178
	&__card
		padding: 10px 10px 0px 10px
		margin-bottom: 40px
		width: 24%
		position: relative
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			padding: 11px 11px 0px 11px
		@media screen and (max-width: 1440px)
			width: 32%
		@media screen and (max-width: 1200px) and (min-width: 889px)
			width: 45%
		@media screen and (max-width: 889px)
			width: 32%
		@media screen and (max-width: 760px)
			padding: 0
			width: 48%
			margin-bottom: vwm(54px)
		&:nth-child(even)
			.modal-info
				right: 0 !important
			
		&-img
			width: 255px
			height: 255px
			background-color: #F7F7F5
			border-radius: 8px
			position: relative
			display: flex
			align-items: center
			justify-content: center
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				width: 284px
				height: 284px
				border-radius: 8px
			@media screen and (max-width: 1730px) and (min-width: 1730px)
				width: 252px
				height: 252px
				border-radius: 8px
			@media screen and (max-width: 760px)
				width: vwm(172px)
				height: vwm(172px)
				border-radius: vwm(8px)
			&--desk
				@media screen and (max-width: 760px)
					display: none
			img
				max-width: 88%
				max-height: 88%
				display: flex
				margin: auto
		&-hover
			position: absolute
			top: 0
			left: 0
			opacity: 0
			width: 255px
			height: 255px
			background-color: #F7F7F5
			border-radius: 8px
			transition: all 0.5s
			display: flex
			align-items: center
			justify-content: center
			&:hover
				opacity: 1
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				width: 284px
				height: 284px
			@media screen and (max-width: 760px)
				display: none
			img
				max-width: 88%
				max-height: 88%
				display: flex
				margin: auto

		&-items
			margin-top: 27px
			padding-left: 6px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				margin-top: 30px
				padding-left: 7px
			@media screen and (max-width: 760px)
				margin-top: vwm(20px)
				padding-left: vwm(6px)
		&-price
			color: #1C1F22
			font-family: "SB Sans Display"
			font-size: 18px
			font-weight: 750
			line-height: 22px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				font-size: 20px
				line-height: 26px
			@media screen and (max-width: 760px)
				font-size: vwm(16px)
		&-item
			color: #1C1F22
			font-family: "SB Sans Text"
			font-size: 12px
			font-weight: 400
			line-height: 16px
			letter-spacing: -0.24px
			margin-top: 8px
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				font-size: 13px
				line-height: 18px
			@media screen and (max-width: 760px)
				font-size: vwm(12px)
				line-height: vwm(14px)
				margin-top: vwm(12px)
			sup
				vertical-align: super
				font-size: 10px
				position: absolute
				margin-top: -2px
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					font-size: 11px
				@media screen and (max-width: 760px)
					font-size: vwm(10px)
					margin-top: vwm(-2px)
			&--credit
				border-radius: 4px
				background: #A8FDE0
				display: table
				padding: 5px
				margin-top: 10px
				position: relative
				@media screen and (max-width: 4920px) and (min-width: 1730px)
					padding: 6px
					margin-top: 11px
				@media screen and (max-width: 760px)
					border-radius: vwm(4px)
					margin-top: vwm(12px)
					font-size: vwm(10px)
					padding: vwm(3px) vwm(10px) vwm(3px) vwm(4px)

				a
					cursor: pointer
					display: flex
					align-items: center
					justify-content: center
					position: absolute
					top: -3px
					width: 10px
					height: 10px
					background-color: #D9D9D9
					border-radius: 50%
					right: 0
					color: #000
					font-family: SB Sans Text
					font-size: 8px
					line-height: 11px
					text-decoration: none
					@media screen and (max-width: 4920px) and (min-width: 1730px)
						top: -3px
						width: 11px
						height: 11px
						font-size: 9px
						line-height: 12px
					@media screen and (max-width: 760px)
						font-size: vwm(8px)
						width: vwm(10px)
						height: vwm(10px)
						top: vwm(-4px)
			&--adress
				display: flex
				align-items: center
				@media screen and (max-width: 760px)
					margin-top: vwm(10px)
				&::before
					content: ''
					background-size: contain
					background-repeat: no-repeat
					background-image: url(~@/assets/img/home.svg)
					width: 16px
					height: 16px
					margin-right: 6px
					margin-left: 2px
					@media screen and (max-width: 4920px) and (min-width: 1730px)
						width: 18px
						height: 18px
					@media screen and (max-width: 760px)
						width: vwm(14px)
						height: vwm(14px)
						margin-right: vwm(6px)
						margin-left: vwm(2px)
			&--gray
				margin-top: 6px
				color: #8C959C
				&:first-letter
					text-transform: uppercase
				@media screen and (max-width: 760px)
					margin-top: vwm(9px)
			&:nth-child(6)
				@media screen and (max-width: 760px)
					margin-top: vwm(7px) !important
			&:nth-child(7)
				@media screen and (max-width: 760px)
					margin-top: vwm(7px) !important			
		&-btn
			display: flex
			width: 120px
			height: 36px
			padding: 8px 16px
			justify-content: center
			align-items: center
			gap: 4px
			border-radius: 32px
			background: #FC4C02
			color: #FFF
			text-align: center
			text-decoration: none
			font-family: "SB Sans Text"
			font-size: 14px
			font-weight: 400
			line-height: 20px
			letter-spacing: -0.28px
			margin-top: 20px
			cursor: pointer
			transition: all 0.5s
			@media screen and (max-width: 4920px) and (min-width: 1730px)
				width: 134px
				height: 40px
				padding: 9px 18px
				font-size: 16px
				line-height: 22px
				margin-top: 22px
			@media screen and (max-width: 760px)
				width: vwm(120px)
				height: vwm(36px)
				border-radius: vwm(1000px)
				margin-top: vwm(17px)
				font-size: vwm(14px)
			&:hover
				background: #E44502
								
			
			
</style>