<template>
	<div class="container">
		<div class="shop">
      <Filters />
      <FiltersMobile />
      <div class="shop__catalog">
        <Loader v-if="loading" />
        <template v-else>
          <template v-if="category == 'all'">
            <BannerNone v-if="flats['podborka_6'][0].length == 0 && flats['podborka_7'][0].length == 0 && flats['podborka_2'][0].length == 0 && flats['podborka_8'][0].length == 0 && flats['podborka_3'][0].length == 0" />
            <template v-else>
                <template v-if="flats['podborka_6'] && flats['podborka_6'].length > 0 && flats['podborka_6'][0].length">
                  <Banner2 :title="'podborka_6'" />
                  <Catalog :list="flats['podborka_6']" :name="key" />
                </template>
                <template v-if="flats['podborka_7'] && flats['podborka_7'].length > 0 && flats['podborka_7'][0].length">
                  <Banner2 :title="'podborka_7'" />
                  <Catalog :list="flats['podborka_7']" :name="key" />
                </template>
                <template v-if="flats['podborka_2'] && flats['podborka_2'].length > 0 && flats['podborka_2'][0].length">
                  <Banner2 :title="'podborka_2'" />
                  <Catalog :list="flats['podborka_2']" :name="key" />
                </template>
                <template v-if="flats['podborka_8'] && flats['podborka_8'].length > 0 && flats['podborka_8'][0].length">
                  <Banner2 :title="'podborka_8'" />
                  <Catalog :list="flats['podborka_8']" :name="key" />
                </template>
                <template v-if="flats['podborka_3'] && flats['podborka_3'].length > 0 && flats['podborka_3'][0].length">
                  <Banner2 :title="'podborka_3'" />
                  <Catalog :list="flats['podborka_3']" :name="key" />
                </template>
                <!-- <template v-if="flats['podborka_1'] && flats['podborka_1'].length > 0 && flats['podborka_1'][0].length">
                  <Banner2 :title="'podborka_1'" />
                  <Catalog :list="flats['podborka_1']" :name="key" />
                </template> -->
                <!-- <template v-if="flats['podborka_4'] && flats['podborka_4'].length > 0 && flats['podborka_4'][0].length">
                  <Banner2 :title="'podborka_4'" />
                  <Catalog :list="flats['podborka_4']" :name="key" />
                </template>
                <template v-if="flats['podborka_5'] && flats['podborka_5'].length > 0 && flats['podborka_5'][0].length">
                  <Banner2 :title="'podborka_5'" />
                  <Catalog :list="flats['podborka_5']" :name="key" />
                </template> -->
            </template>
          </template>
          <template v-else>
              <BannerNone v-if="flats[0].length == 0" />
              <div v-else>
                <template v-if="flats && flats.length > 0 && flats[0].length">
                  <Banner2 :title="category" />
                  <Catalog :list="flats" :name="category" />
                </template>
              </div>
          </template>
        </template>
        <!-- <Banner2 />
        <Catalog /> -->
        <BannerBottom />
      </div>
		</div>
	</div>
</template>

<script>
  import Filters from '@/components/Filter.vue'
  import FiltersMobile from '@/components/FilterMobile.vue'
  import Catalog from '@/components/Catalog.vue'
  import Banner2 from '@/components/Banner2.vue'
  import BannerNone from '@/components/BannerNone.vue'
  import BannerBottom from '@/components/BannerBottom.vue'
  import Loader from '@/components/Loader.vue'
  export default {
    name: "Shop",
    components: {
      Filters,
      Catalog,
      Banner2,
      BannerNone,
      BannerBottom,
      FiltersMobile,
      Loader,
    },
    computed: {
      loading() {
        return this.$store.getters.loading
      },
      category() {
        return this.$store.getters.category
      },
      flats() {
        if (this.category == 'all') {
          return this.$store.getters.flats
        } else {
          let category = this.category
          return this.$store.getters.flats[category]
        }
      },
    }
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"
.shop
  display: flex
  margin-top: 47px
  @media screen and (max-width: 4920px) and (min-width: 1730px)
    margin-top: 50px
    margin-left: 10px
  @media screen and (max-width: 889px) 
    display: block
    margin-top: vwm(20px)
  &__catalog
    width: 100%
    margin-left: 21px
    @media screen and (min-width: 1920px)
      width: 1312px
    @media screen and (max-width: 4920px) and (min-width: 1730px)
      margin-left: 23px
    @media screen and (max-width: 1200px) and (min-width: 889px)
      width: 585px
    @media screen and (max-width: 889px) 
      margin-left: 0
    @media screen and (max-width: 760px)
      margin-left: 0
      margin-top: vwm(24px)


</style>