<template>
	<div class="filter-mobile">
		<div class="filter-mobile__btn" @click="showModal = true">Фильтры 
			<a href="#">
				<img src="../assets/img/Filters.svg" alt="" class="filter-mobile__img">
				<div class="filter-mobile__status"></div>
			</a>
		</div>
		<div class="filter-mobile__status"></div>

		<div class="filter-mobile__modal" v-show="showModal == true">
			<div class="filter-mobile__modal-top">
				<div class="filter-mobile__modal-close" @click="showModal = false"></div>
				<div class="filter-mobile__modal-title">Фильтры</div>
				<a href="#" class="filter-mobile__modal-clear" @click="reset">Очистить</a>
			</div>
			<div class="filter-mobile__modal-main">
				<div class="filter__label">Цена, руб</div>
				<div id="slider-mob"></div>
				<div class="filter__slider-content">
					<div class="filter__slider-field">
						<div class="filter__slider-prefix">от</div>
						<input type="tel" class="filter__slider-label" v-model="rangeStart" v-number="number" @change="updateMin" ref="min" @blur="updateMin">
						<div class="filter__slider-sufix">₽</div>
					</div>
					<div class="filter__slider-field">
						<div class="filter__slider-prefix">от</div>
						<input type="tel" class="filter__slider-label" v-model="rangeEnd" v-number="number" @change="updateMax" ref="max" @blur="updateMax">
						<div class="filter__slider-sufix">₽</div>
					</div>
				</div>
				<div class="filter__item filter__select" v-if="regions">
				<v-select :searchable="false" :options="regions" v-model="region" label="name" @input="changeCity">
					<template #open-indicator="{ attributes }">
						<svg v-bind="attributes" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="Arrow_down">
								<path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M5.29293 8.29286C5.68347 7.90235 6.31664 7.90238 6.70714 8.29293L11.9997 13.586L17.2931 8.29572C17.6837 7.90531 18.3169 7.90549 18.7073 8.29613C19.0977 8.68677 19.0975 9.31994 18.7069 9.71035L12.7064 15.7074C12.3158 16.0977 11.6828 16.0976 11.2923 15.7071L5.29286 9.70707C4.90235 9.31653 4.90238 8.68336 5.29293 8.29286Z" fill="#8654CC"/>
							</g>
						</svg>
					</template>
				</v-select>
				</div>
					<a class="filter-mobile__modal-btn" @click="submit">Применить</a>
				</div>
			</div>
		
	</div>
</template>

<script>
	import noUiSlider from 'nouislider';
	import 'nouislider/dist/nouislider.css';

	export default {
		name: "FiltersMobile",
		data() {
			return {
				slider: null,
				rangeStart: 0,
				rangeEnd: 0,
				region: null,
				showModal: false,
				number: {
					decimal: '.',
					separator: ' ',
					precision: 0,
					min: 0,
					max: 0,
					masked: true,
				},
			}
		},
		computed: {
			regions() {
				return this.$store.getters.regions;
			},
			filters() {
				return this.$store.getters.filters;
			},
			category() {
				return this.$store.getters.category;
			},
		},
		methods: {
			changeCity() {
				this.loadFlats(false);
				if (this.region.id == 14) {
					this.sendEvent('filter_moskva')
				}
				if (this.region.id == 1) {
					this.sendEvent('filter_piter')
				}
				if (this.region.id == 7) {
					this.sendEvent('filter_ekater')
				}
				if (this.region.id == 6) {
					this.sendEvent('filter_novgorod')
				}
				if (this.region.id == 8) {
					this.sendEvent('filter_kazan')
				}
				if (this.region.id == 9) {
					this.sendEvent('filter_vladi')
				}
				if (this.region.id == 12) {
					this.sendEvent('filter_habarovsk')
				}
				if (this.region.id == 11) {
					this.sendEvent('filter_blago')
				}
				if (this.region.id == 10) {
					this.sendEvent('filter_saha')
				}
				// if (this.region.id == 9) {
				// 	this.sendEvent('filter_yaros')
				// }
				if (this.region.id == 2) {
					this.sendEvent('filter_yaros')
				}
				if (this.region.id == 5) {
					this.sendEvent('filter_novo')
				}
				if (this.region.id == 4) {
					this.sendEvent('filter_obninsk')
				}
				// if (this.region.id == 13) {
				// 	this.sendEvent('filter_moskva')
				// }
			},
			updateMax() {
				if (this.rangeEnd && this.rangeEnd.length > 2) {
					let s = this.rangeStart.replace(/ /g, '');
					let en = this.rangeEnd.replace(/ /g, '');
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				} else {
					let id = this.region.id;
					let category = this.category;
					let prices = this.filters.prices[id][category];
					let s = Math.round(prices[0]);
					let en = Math.round(prices[1]);
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.rangeStart = start;
					this.rangeEnd = end;
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				}
				this.sendEvent('filter_cena');
			},
			updateMin() {
				if (this.rangeStart && this.rangeStart.length > 2) {
					let s = this.rangeStart.replace(/ /g, '');
					let en = this.rangeEnd.replace(/ /g, '');
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				} else {
					let id = this.region.id;
					let category = this.category;
					let prices = this.filters.prices[id][category];
					let s = Math.round(prices[0]);
					let en = Math.round(prices[1]);
					let start = parseInt(s, 10);
					let end = parseInt(en, 10);
					this.rangeStart = start;
					this.rangeEnd = end;
					this.slider.updateOptions({
						'start': [start, end],
					}, true)
				}
				this.sendEvent('filter_cena');
			},
			submit() {
				this.loadFlats(false);
				this.showModal = false;
			},
			loadFlats(is_first_run = false) {
				let id = this.region.id;
				let category = this.category;
				if (is_first_run == true) {
					let prices = this.filters.prices[id][category];
					this.$store.dispatch('setPriceRange', prices);
					let start = Math.round(prices[0]);
					let end = Math.round(prices[1]);
					this.number.min = 0;
					this.number.max = end;
					this.slider.updateOptions({
						'start': [start, end],
						range: {
							'min': Math.round(prices[0]),
							'max': Math.round(prices[1])
						}
					}, true)
				}
				this.$store.dispatch('getFlats', this.region.id);
				this.$store.dispatch('setRegion', this.region);
			},
			reset() {
				let id = this.region.id;
				let category = this.category;
				let prices = this.filters.prices[id][category];
				this.$store.dispatch('setPriceRange', prices);
				let start = Math.round(prices[0]);
				let end = Math.round(prices[1]);
				this.slider.updateOptions({
					'start': [start, end],
					range: {
						'min': Math.round(prices[0]),
						'max': Math.round(prices[1])
					}
				}, true)
				this.$store.dispatch('getFlats', this.region.id);
				this.$store.dispatch('setRegion', this.region);
				this.showModal = false;
			},
			formatNumber(number) {
				return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			},
		},
		mounted() {
			this.$store.dispatch('getRegions').then(() => {
				this.region = this.regions[0]; // Москва по умолчанию
				this.$store.dispatch('setRegion', this.region);
				this.$store.dispatch('getFilters').then(() => {
					this.loadFlats(true);
				})
			})
			this.$nextTick(() => {
				this.slider = noUiSlider.create(document.getElementById('slider-mob'), {
					start: [this.rangeStart, this.rangeEnd],
					connect: true,
					range: {
						'min': Math.round(this.rangeStart),
						'max': Math.round(this.rangeEnd)
					}
				});
				this.slider.on('update', (values) => {
					let start = Math.round(values[0]);
					let end = Math.round(values[1]);
					this.$store.dispatch('setPriceRange', [start, end]);
					this.rangeStart = start;
					this.rangeEnd = end;
				});
				this.slider.on('change', () => {
					this.loadFlats();
					this.sendEvent('filter_cena');
				});
			})
		},
		watch: {
			category(val) {
				let id = this.region.id;
				let category = val;
				let prices = this.filters.prices[id][category];
				let start = Math.round(prices[0]);
				let end = Math.round(prices[1]);
				this.$store.dispatch('setPriceRange', [start, end]);
				this.slider.updateOptions({
					range: {
						'min': start,
						'max': end,
					},
					'start': [start, end],
				}, true)
			}
		}
	}
</script>

<style lang="sass">
@import "../sass/functions.sass"
.filter-mobile
	display: none
	@media screen and (max-width: 889px)
		display: block
	@media screen and (max-width: 760px)
		display: block
	.filter__select .v-select .vs__selected-options
		max-height: 30px
	.vs__dropdown-option
		padding-left: vwm(14px) !important
		padding-right: vwm(14px) !important
		height: vwm(30px) !important
	.filter__select .v-select .vs__dropdown-toggle
		font-size: 14px
	.filter__slider-content input
		padding-left: vwm(28px)
	.filter__slider-prefix
		text-transform: uppercase
		top: vwm(10px) !important
	.filter__slider-sufix
		text-transform: uppercase
		top: vwm(10px) !important

	&__btn
		cursor: pointer
		color: #1C1F22
		font-family: SB Sans Text
		font-size: vwm(14px)
		font-weight: 400
		line-height: vwm(20px)
		letter-spacing: vwm(-0.28px)
		display: flex
		align-items: flex-start
		width: calc( 100% + vwm(8px))
		margin-left: vwm(0px)
		@media screen and (max-width: 889px) and (min-width: 760px)
			margin-left: 0px
			font-size: 15px
		a
			position: relative
			margin-left: vwm(9px)
			cursor: pointer
			@media screen and (max-width: 889px) and (min-width: 760px)
				margin-left: 9px
				margin-top: 4px
			.filter-mobile__img
				@media screen and (max-width: 889px) and (min-width: 760px)
					width: 20px
					height: 20px
			.filter-mobile__status
				position: absolute
				width: vwm(5px)
				height: vwm(5px)
				background: #8654CC
				border-radius: 50%
				top: 0
				right: vwm(-5px)
				@media screen and (max-width: 889px) and (min-width: 760px)
					width: 5px
					height: 5px
					top: 5px
					right: 0px
					
					
	&__modal
		display: none
		@media screen and (max-width: 889px)
			display: block
			top: 0
			left: 0
			position: fixed
			z-index: 200
			width: 100%
			height: 100%
			background-color: #FFF
		&-top
			display: flex
			align-items: center
			justify-content: center
			position: relative
			padding: vwm(11px) vwm(14px)
			border-bottom: 1px solid rgba(212, 218, 223, 0.5)
			@media screen and (max-width: 889px) and (min-width: 760px)
				padding: vwm(5px) vwm(8px)
		&-title
			display: flex
			justify-content: center
			position: relative
			color: #1C1F22
			text-align: center
			font-family: SB Sans Display
			font-size: vwm(18px)
			font-style: normal
			font-weight: 750
			@media screen and (max-width: 889px) and (min-width: 760px)
				font-size: vwm(8px)
		&-close
			cursor: pointer
			position: absolute
			left: vwm(12px)
			background-size: contain
			background-repeat: no-repeat
			background-image: url(~@/assets/img/Cross.svg)
			width: vwm(24px)
			height: vwm(24px)
			@media screen and (max-width: 889px) and (min-width: 760px)
				width: vwm(10px) !important
				height: vwm(10px) !important
				left: vwm(6px)
		&-clear
			text-decoration: none
			position: absolute
			right: 0
			margin-right: vwm(8px)
			color: #862AC1
			cursor: pointer
			@media screen and (max-width: 760px)
				margin-right: vwm(16px)
		&-main
			padding: 0 vwm(54px) 0 vwm(54px)
			@media screen and (max-width: 760px)
				padding: 0 vwm(30px)
				margin-top: vwm(36px)
				.noUi-target
					width: 100%
					height: 2px
					margin-top: 26px
					margin-bottom: 23px
				.noUi-connect
					height: 2px
				.noUi-handle
					width: 26px !important
					top: -12px !important
					right: -12px !important
					height: 26px !important

		&-btn
			cursor: pointer
			display: flex
			width: 100%
			height: vwm(36px)
			padding: vwm(8px) vwm(16px)
			justify-content: center
			align-items: center
			gap: vwm(4px)
			border-radius: vwm(1000px)
			background: #FC4C02
			margin: auto
			margin-top: vwm(29px)
			color: #FFF
			font-family: SB Sans Text
			font-size: vwm(14px)
			line-height: vwm(20px)
			letter-spacing: vwm(-0.28px)
			@media screen and (max-width: 889px) and (min-width: 760px)
				margin-top: vwm(15px)
				width: 100%
				height: vw(30px)
				font-size: vw(30px)
</style>