<template>
	<div class="banner">
		<div class="banner__title">По заданным параметрам<br>не удалось ничего подобрать</div>
		<div class="banner__subtitle">Попробуйте изменить настройки фильтров</div>
		<a class="banner__btn" href="https://www.pik.ru/?utm_source=megamarket&utm_medium=display&utm_content=logo" target="_blank">Смотреть все квартиры на pik.ru</a>
	</div>
</template>

<script>
  export default {
	name: "BannerNone",

  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.banner
	width: 1130px
	text-align: center
	margin-top: 48px
	@media screen and (min-width: 1920px)
		width: 1290px
	@media screen and (max-width: 4920px) and (min-width: 1729px)
		width: 1260px
	@media screen and (max-width: 1440px)
		width: 845px
	@media screen and (max-width: 1200px)
		width: 585px
	@media screen and (max-width: 889px)
		width: 825px
	@media screen and (max-width: 760px)
		width: 100%
		margin-top: vwm(32px)
	&__title
		color: #1C1F22
		text-align: center
		font-family: SB Sans Text
		font-size: 48px
		font-weight: 600
		line-height: 57.6px
		letter-spacing: -0.912px
		@media screen and (max-width: 1200px)
			font-size: 38px
		@media screen and (max-width: 760px)
			line-height: 37.6px
	&__subtitle
		margin-top: 12px
		color: #1C1F22
		text-align: center
		font-family: SB Sans Text
		font-size: 20px
		font-weight: 400
		line-height: 24px
		letter-spacing: -0.38px
		@media screen and (max-width: 1200px)
			font-size: 15px
	&__btn
		text-decoration: none
		cursor: pointer
		margin: auto
		margin-top: 32px
		display: flex
		width: 308px
		height: 36px
		padding: 8px 16px
		justify-content: center
		align-items: center
		gap: 4px
		border-radius: 32px
		background: #FC4C02
		color: #FFF
		text-align: center
		font-family: SB Sans Text
		font-size: 14px
		font-weight: 600
		line-height: 20px
		letter-spacing: -0.28px


</style>