import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import VueNumberFormat from '@coders-tm/vue-number-format'

Vue.use(VueNumberFormat, { precision: 4 })


Vue.component("v-select", vSelect)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    sendEvent(name) {
      if (window.ym) {
        window.ym(95355767,'reachGoal', name)
      } else {
        console.log(window.ym)
      }
    }    
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

