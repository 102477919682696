import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views'
import Gift from '../views/Gift'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Главная',
    },
  },
  {
    path: '/gift',
    component: Gift,
    meta: {
      title: 'Подарок',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router
