<template>
	<div class="main">
		<div class="banner" v-if="title == 'podborka_2'">
			<div class="banner__text">
				<div class="banner__text-title">Квартиры<br>с отделкой</div>
				<div class="banner__text-subtitle">в построенных домах</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>

		<div class="banner banner--2" v-if="title == 'podborka_1'">
			<div class="banner__text">
				<div class="banner__text-title">Квартиры<br>с двумя санузлами</div>
				<div class="banner__text-subtitle">удобно для большой семьи</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>

		<div class="banner banner--3" v-if="title == 'podborka_3'">
			<div class="banner__text">
				<div class="banner__text-title">Семейная<br>подборка</div>
				<div class="banner__text-subtitle">большие квартиры 90+ м2</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>

		<div class="banner banner--4" v-if="title == 'podborka_4'">
			<div class="banner__text">
				<div class="banner__text-title">Французский<br>балкон</div>
				<div class="banner__text-subtitle">дом, где всегда много света</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>

		<div class="banner banner--5" v-if="title == 'podborka_5'">
			<div class="banner__text">
				<div class="banner__text-title">Квартиры в проектах<br>с PlayHub и LocalPlay</div>
				<div class="banner__text-subtitle">лучшие детские площадки в районах ПИК</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>

		<div class="banner banner--6" v-if="title == 'podborka_6'">
			<div class="banner__text">
				<div class="banner__text-title">Заселение<br>до конца года</div>
				<div class="banner__text-subtitle">если не хочется <br>долго ждать</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>
		<div class="banner banner--7" v-if="title == 'podborka_7'">
			<div class="banner__text">
				<div class="banner__text-title">Квартиры<br>у метро</div>
				<div class="banner__text-subtitle">до 15 минут пешком</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>
		<div class="banner banner--8" v-if="title == 'podborka_8'">
			<div class="banner__text">
				<div class="banner__text-title">Доступные<br>квартиры</div>
				<div class="banner__text-subtitle">недорого и в пределах <br>МКАД и КАД</div>
			</div>
			<div class="banner__gradietn"></div>
		</div>
	</div>

</template>

<script>
  export default {
	name: "Banner",
	props: {
		title: {
			type: String
		}
	}
  }
</script>

<style lang="sass" scoped>
@import "../sass/functions.sass"
.banner
	//margin-top: vw(48px)
	display: flex
	border-radius: 24px
	background-size: cover
	background-repeat: no-repeat
	background-image: url(~@/assets/img/bann2.jpg)
	width: 1130px
	height: 170px
	background-position: 50% 61%
	position: relative
	margin-bottom: 26px
	@media screen and (min-width: 1920px)
		width: 1290px
		background-size: cover
	@media screen and (max-width: 4920px) and (min-width: 1730px)
		border-radius: 24px
		background-size: cover
		width: 1260px
		height: 189px
		margin-bottom: 29px
	@media screen and (max-width: 1730px) and (min-width: 1730px)
		width: 1081px !important
	@media screen and (max-width: 1440px)
		width: 845px
	@media screen and (max-width: 1200px)
		width: 585px
	@media screen and (max-width: 889px)
		background-position: 45% 58.5%
		width: 825px
	@media screen and (max-width: 760px)
		border-radius: vwm(12px)
		width: 100%
		height: vwm(120px)
		background-size: vwm(340px)
		background-position: 65% 85%
		margin-top: vwm(32px)
	&--2
		background-image: url(~@/assets/img/bann3.jpg)
		background-position: 38% 68%
		@media screen and (max-width: 760px)
			background-size: cover
			background-position: 65% 83%
	&--3
		background-image: url(~@/assets/img/bann4.jpg)
		background-position: 90% 67%
		background-size: 1280px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			background-size: 1427px
		@media screen and (max-width: 760px)
			background-size: vwm(450px) !important
			background-position: 50% 95% !important
	&--4
		background-image: url(~@/assets/img/bann5.jpg)
		background-position: 80% 49%
		background-size: 1300px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			background-size: 1449px
		@media screen and (max-width: 760px)
			background-size: vwm(375px) !important
			background-position: 106% 28% !important
	&--5
		background-image: url(~@/assets/img/bann6.jpg)
		background-position: 54% 53%
		background-size: 1140px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			background-size: 1270px
		@media screen and (max-width: 760px)
			background-size: vwm(340px) !important
			background-position: 54% 5% !important
	&--6
		background-image: url(~@/assets/img/banner-6.jpg)
		background-position: 60% 69%
		background-size: 1130px 650px
		.banner__text-subtitle
			br
				display: none
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			background-size: 1270px
		@media screen and (max-width: 760px)
			.banner__text-subtitle
				br
					display: block
			background-size: vwm(373px) vwm(208px) !important
			background-position: 42% 64% !important
	&--7
		background-image: url(~@/assets/img/banner-7.jpg)
		background-position: 50% 73%
		background-size: 1133px 630px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			background-size: 1270px
		@media screen and (max-width: 760px)
			background-size: vwm(345px) !important
			background-position: 55% 60% !important
	&--8
		background-image: url(~@/assets/img/banner-8.jpg)
		background-position: 52% 54%
		background-size: 1356px 800px
		.banner__text-subtitle
			br
				display: none
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			background-size: 1270px
		@media screen and (max-width: 760px)
			.banner__text-subtitle
				br
					display: block
			background-size: vwm(500px) vwm(280px) !important
			background-position: 33% 70% !important
	&__text
		z-index: 100
		padding-top: 28px
		padding-left: 32px
		color: #FFF
		font-family: "Graphik LC"
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			padding-top: 31px
			padding-left: 35px
		@media screen and (max-width: 760px)
			padding-top: vwm(10px)
			padding-left: vwm(12px)
		&-title
			font-size: 35px
			font-style: normal
			font-weight: 600
			line-height: 36px
			@media screen and (max-width: 4920px) and (min-width: 1728px)
				font-size: 39px
				line-height: 42px
			@media screen and (max-width: 760px)
				font-size: vwm(16px)
				line-height: vwm(18px)
		&-subtitle
			margin-top: 21px
			font-size: 18px
			font-style: normal
			font-weight: 500
			line-height: 18px
			@media screen and (max-width: 4920px) and (min-width: 1728px)
				margin-top: 23px
				font-size: 20px
				line-height: 22px
			@media screen and (max-width: 760px)
				margin-top: vwm(5px)
				font-size: vwm(10px)
				line-height: vwm(14px)
	&__gradietn
		z-index: 10
		position: absolute
		width: 805px
		height: 170px
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0.28%, rgba(255, 255, 255, 0.00) 87.5%)
		top: 0
		border-radius: 24px 0 0 24px
		@media screen and (max-width: 4920px) and (min-width: 1730px)
			width: 897px
			height: 189px
			border-radius: 24px 0 0 24px
		@media screen and (max-width: 1200px) and (min-width: 889px)
			width: 400px
		@media screen and (max-width: 760px)
			background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0.28%, rgba(255, 255, 255, 0.00) 86.5%)
			border-radius: vwm(8px)
			width: 100%
			height: vwm(120px)
</style>