<template>
  <div class="gift__bottom gift__bottom--end">
    <div class="gift__bottom-form gift__bottom-form--end">
      <div class="gift__bottom-title">
        <p>Результаты теста</p>
        <h1>«Домашний уют»</h1>
      </div>

      <div class="gift__bottom-main gift__bottom-main--end">
        <p>Какое для вас лучшее <span></span>место в квартире?</p>
        <div id="trigger"></div>
        <div class="gift__bottom-results">
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">42%</div>
            <div class="gift__bottom-line" :class="{'gift__bottom-line--0': !is_animate_1}">Кухня</div>
          </div>
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">53%</div>
            <div class="gift__bottom-line gift__bottom-line--2" :class="{'gift__bottom-line--0': !is_animate_1}">Гостиная</div>
          </div>
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">5%</div>
            <div class="gift__bottom-line gift__bottom-line--3 gift__bottom-line--0">Балкон</div>
          </div>
        </div>

        <p>Что больше придает уюта?</p>

        <div class="gift__bottom-results">
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">62%</div>
            <div class="gift__bottom-line gift__bottom-line--4" :class="{'gift__bottom-line--0': !is_animate_2}">Шторы</div>
          </div>
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">12%</div>
            <div class="gift__bottom-line gift__bottom-line--5" :class="{'gift__bottom-line--0': !is_animate_2}">Свечи</div>
          </div>
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">26%</div>
            <div class="gift__bottom-line gift__bottom-line--6" :class="{'gift__bottom-line--0': !is_animate_2}">Комнатные растения</div>
          </div>
        </div>

        <p>Какой аромат придает <span></span>больше уюта дома?</p>

        <div class="gift__bottom-results">
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">51%</div>
            <div class="gift__bottom-line gift__bottom-line--7" :class="{'gift__bottom-line--0': !is_animate_3}">Свежий хлеб</div>
          </div>
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">19%</div>
            <div class="gift__bottom-line gift__bottom-line--8" :class="{'gift__bottom-line--0': !is_animate_3}">Диффузор</div>
          </div>
          <div class="gift__bottom-item">
            <div class="gift__bottom-count">30%</div>
            <div class="gift__bottom-line gift__bottom-line--9" :class="{'gift__bottom-line--0': !is_animate_3}">Цветы</div>
          </div>
        </div>

        <a class="gift__bottom-btn gift__bottom-btn--end" href="https://megamarket.ru/promo/smart-home/" target="_blank">Смотреть подборку товаров</a>
      </div>

    </div>

    <div class="gift__bottom-vase"><img src="../assets/img/vase.svg" alt=""></div>
    <div class="gift__bottom-flower gift__bottom-flower--end"></div>
  </div>
  
</template>

<script>
  import Vue from 'vue';
  import VueToast from 'vue-toast-notification';
  import VueMask from 'v-mask';
  import Vuelidate from 'vuelidate';
  import VueClipboard from 'vue-clipboard2'
  import { required, email, minLength } from 'vuelidate/lib/validators'
  import { VueReCaptcha } from 'vue-recaptcha-v3';
  import 'vue-toast-notification/dist/theme-bootstrap.css';

  Vue.use(VueToast);
  Vue.use(Vuelidate);
  Vue.use(VueMask);
  Vue.use(VueClipboard);
  Vue.use(VueReCaptcha, { siteKey: '6Le3mA4pAAAAAKADmhoJsBT5odSBjTHmMDYBqgB9' })

  export default {
    name: "TestEnd",
    data() {
      return {
        is_animate_1: false,
        is_animate_2: false,
        is_animate_3: false,
      }
    },
    validations: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        minLength: minLength(16),
      },
      agree_1: {
        checked: value => value === true
      },
      agree_2: {
        checked: value => value === true
      },
    },
    computed: {
      errors() {
        return this.$store.getters.errors; 
      }
    },
    mounted() {
      window.addEventListener("scroll", this.scrollHandler);
    },
    methods: {
      clearError(item) {
        var index = this.errors.indexOf(item);
        if (index !== -1) {
          this.$store.dispatch('removeError', index)
        }
      },
      scrollHandler() {
        const { documentElement, body } = document;
        let windowScroll = body.scrollTop || documentElement.scrollTop;
        let height = documentElement.scrollHeight - documentElement.clientHeight;
        if (height == 0) {
          windowScroll = 100;
          height = 10000;
        }
        let progressNumber = (windowScroll / height) * 100;
        console.log(progressNumber);
        if (window.innerWidth > 1000) {
          if (progressNumber > 41) {
            this.is_animate_1 = true
          } else {
            this.is_animate_1 = false
          }
          if (progressNumber > 50) {
            this.is_animate_2 = true
          } else {
            this.is_animate_2 = false
          }
          if (progressNumber > 65) {
            this.is_animate_3 = true
          } else {
            this.is_animate_3 = false
          }
        } else {
          if (progressNumber > 25) {
            this.is_animate_1 = true
          } else {
            this.is_animate_1 = false
          }
          if (progressNumber > 35) {
            this.is_animate_2 = true
          } else {
            this.is_animate_2 = false
          }
          if (progressNumber > 35) {
            this.is_animate_3 = true
          } else {
            this.is_animate_3 = false
          }
        }
      }
    }
  }
  
</script>

<style lang="sass">
@import "../sass/functions.sass"
.field-error
  border-color: red !important
.error-checkbox .custom-checkbox + label::before
  border-color: red !important
.copy-link
  display: flex
  color: #323232
  justify-content: center
  margin-top: 4px
  text-align: center
  font-family: SB Sans Display
  font-size: 12px
  font-weight: 400
  line-height: 14.4px
  text-decoration-line: underline
  opacity: 0.5
  cursor: pointer
  @media screen and (max-width: 760px)
    margin-top: vwm(12px)
.error
  opacity: 0.5
  background: #6933B0
.checkbox
  display: inline-flex
  margin-top: 20px
  color: rgba(50, 50, 50, 0.50)
  font-size: 10px
  font-weight: 400
  line-height: normal
  width: 100%
  a
    color: rgba(50, 50, 50, 0.50)
  @media screen and (max-width: 899px)
    margin-top: 12px
  @media screen and (max-width: 760px)
    font-size: vwm(7px)
    margin-top: vwm(10px)
  &--2
    margin-top: 3px

.gift
  &__bottom
    &-results
      text-align: center
      display: inline-block
      margin-bottom: 30px
      @media screen and (max-width: 760px)
        margin-bottom: vwm(20px)
    &-item 
      display: flex
      color: #FFF
      font-size: 18px
      font-weight: 400
      line-height: 21.6px
      align-items: center
      margin-bottom: 9px
      justify-content: flex-start
      margin-left: -10px
      min-width: 292px
      @media screen and (max-width: 760px)
        font-size: vwm(12px)
        line-height: vwm(12px)
        margin-bottom: vwm(7px)
        justify-content: flex-start
        margin-left: vwm(-8px)
        min-width: vwm(178px)
      &:last-child
        margin-bottom: 0px
    &-count
      margin-right: 14px
      width: 49px
      min-width: 49px
      @media screen and (max-width: 760px)
       margin-right: vwm(14px)
    &-line
      width: 168px
      height: 34px
      background: #21BA72
      text-align: left
      display: flex
      align-items: center
      padding-left: 14px
      white-space: nowrap
      transition: all 1s
      @media screen and (max-width: 760px)
        width: vwm(84px)
        height: vwm(25px)
        padding-left: vwm(14px)
      &--0
        width: 0 !important
        margin-right: auto
      &--2
        width: 212px
        @media screen and (max-width: 760px)
          width: vwm(102px)
      &--3
        width: 20px
        @media screen and (max-width: 760px)
          width: vwm(12px)
      &--4
        width: 220px
        @media screen and (max-width: 760px)
          width: vwm(110px)
      &--5
        width: 55px
        @media screen and (max-width: 760px)
          width: vwm(24px)
      &--6
        width: 104px
        @media screen and (max-width: 760px)
          width: vwm(52px)
      &--7
        width: 205px
        @media screen and (max-width: 760px)
          width: vwm(102px)
      &--8
        width: 76px
        @media screen and (max-width: 760px)
          width: vwm(38px)
      &--9
        width: 120px
        @media screen and (max-width: 760px)
          width: vwm(60px)
    &-form
      z-index: 200
      margin: auto
      padding: 48px 104px
      height: 435px
      width: 844px
      border-radius: 24px
      background: #8654CC
      @media screen and (max-width: 1200px)
        height: 338.502px
        padding: 37.352px 60px
        width: 656px
        border-radius: 18.676px
      @media screen and (max-width: 889px)
        width: 608px
        height: 313px
        padding: 34px 60px 14px 60px
      @media screen and (max-width: 760px)
        width: vwm(328px)
        height: vwm(409px)
        padding: vwm(30px) vwm(20px) vwm(20px) vwm(20px)
      &--info
        height: 632px
        padding: 48px 20px 19px 20px
        @media screen and (max-width: 1200px)
          height: 649px
          width: 656px
          padding: 37px 16px 16px 16px
        @media screen and (max-width: 889px)
          width: 608px
          height: 607px
          padding: 34px 16px 16px 16px
        @media screen and (max-width: 760px)
          width: vwm(328px)
          height: vwm(517px)
          padding: vwm(30px) vwm(14px) vwm(10px) vwm(14px)
        .gift__bottom-title
          h1
            font-size: 40px
            font-weight: 600
            line-height: 48px
            //letter-spacing: -2px
            @media screen and (max-width: 1200px)
              font-size: 32px
              line-height: 38.4px
            @media screen and (max-width: 760px)
              font-size: vwm(19px)
              line-height: vwm(21px)
              font-weight: 500
              //letter-spacing: vwm(-0.5px)
          p
            font-size: 20px
            font-weight: 400
            line-height: 24px
            margin-top: 24px
            @media screen and (max-width: 1200px)
              margin-top: 14px
              font-size: 18px
              line-height: 21.6px
            @media screen and (max-width: 889px)
              margin-top: 8px
              font-size: 16px
              line-height: 20.6px
            @media screen and (max-width: 760px)
              font-size: vwm(12px)
              line-height: vwm(14px)
      &--promo
        height: 553px
        padding: 48px 20px 20px 20px
        @media screen and (max-width: 1200px)
          padding: 37px 16px 16px 16px
          height: 468px
        @media screen and (max-width: 889px)
          height: 458px
          width: 608px
          padding: 34px 16px 16px 16px
        @media screen and (max-width: 760px)
          width: vwm(328px)
          height: vwm(378px)
          padding: vwm(30px) vwm(14px) vwm(10px) vwm(14px)
        .gift__bottom-title
          h1
            font-size: 40px
            font-weight: 600
            line-height: 48px
            letter-spacing: 0px
            @media screen and (max-width: 1200px)
              font-size: 32px
              line-height: 38px
            @media screen and (max-width: 760px)
              font-size: vwm(19px)
              line-height: vwm(21px)
              font-weight: 500
              letter-spacing: vwm(0px)
          p
            font-size: 20px
            font-weight: 400
            line-height: 24px
            margin-top: 24px
            @media screen and (max-width: 1200px)
              margin-top: 14px
              font-size: 18px
              line-height: 21.6px
            @media screen and (max-width: 760px)
              font-size: vwm(12px)
              line-height: vwm(14px)
      &--end
        height: 899px
        width: 828px
        padding: 48px 80px
        @media screen and (max-width: 1200px)
          height: 805px
          width: 656px
          padding: 48px 60px
        @media screen and (max-width: 889px)
          height: 880px
          width: 608px
          padding: 25px 0px
        @media screen and (max-width: 760px)
          width: vwm(328px)
          height: vwm(600px)
          padding: vwm(30px) vwm(20px) vwm(20px) vwm(20px)
        
    &-title
      color: #FFF
      text-align: center
      p
        font-size: 24px
        font-weight: 400
        line-height: 24px
        //letter-spacing: -0.5px
        @media screen and (max-width: 889px)
          font-size: 18px
          line-height: 18px
        @media screen and (max-width: 760px)
          font-size: vwm(14px)
          line-height: vwm(14px)
          //letter-spacing: vwm(0px)

      h1
        font-size: 51px
        font-weight: 600
        line-height: 52px
        //letter-spacing: -2px
        @media screen and (max-width: 1200px)
          font-size: 39px
          line-height: 33px
        @media screen and (max-width: 760px)
          font-size: vwm(19px)
          line-height: vwm(19px)
          //letter-spacing: vwm(0px)
    &-lents
      display: flex
      margin-top: 24px
      justify-content: center
      @media screen and (max-width: 1200px)
        margin-top: 22px
      @media screen and (max-width: 760px)
        margin-top: vwm(13px)
    &-lent
      width: 60px
      height: 4px
      border-radius: 20px
      background: #DFDDDD
      margin-right: 12px
      @media screen and (max-width: 1200px)
        width: 46.69px
        height: 3.113px
        border-radius: 15px
        margin-right: 8px
      @media screen and (max-width: 760px)
        width: vwm(28.69px)
        height: vwm(3.113px)
        border-radius: vwm(14px)
        margin-right: vwm(6px)
      &--active
        background: #21BA72
    &-main
      margin-top: 48px
      text-align: center
      @media screen and (max-width: 889px)
        margin-top: 20px
      @media screen and (max-width: 760px)
        margin-top: vwm(27px)
      p
        color: #FFF
        font-size: 29px
        font-weight: 500
        line-height: 38.4px
        margin-bottom: 17px
        //letter-spacing: 0.5px
        @media screen and (max-width: 1200px)
          font-size: 24px
          line-height: 8.4px
          //letter-spacing: 0.2px
          margin-bottom: 24px
        @media screen and (max-width: 889px)
          font-size: 22px
          line-height: normal
          margin-bottom: 9px
          span
            display: block !important
        @media screen and (max-width: 760px)
          font-size: vwm(16px)
      &--end
        p
          margin-bottom: 27px
          @media screen and (max-width: 889px)
            margin-bottom: 23px
          @media screen and (max-width: 760px)
            margin-bottom: vwm(12px)
      &--width
        width: calc( 100% + 30px)
        margin-left: -15px
      &--end
        margin-top: 30px
        @media screen and (max-width: 760px)
         margin-top: vwm(18px)


    &-btn
      display: flex
      width: 174px
      height: 48px
      padding: 12px 18px
      justify-content: center
      margin: auto
      margin-top: 43px
      align-items: center
      border-radius: 37.333px
      background: #FFF
      color: #8654CC
      text-align: center
      font-size: 20px
      font-weight: 600
      line-height: 24px
      transition: 0.5s
      cursor: pointer
      @media screen and (max-width: 1200px)
        margin-top: 30px
        font-size: 16px
        line-height: 18.676px
        padding: 9.338px 14.007px
        width: 135.401px
        height: 38px
      @media screen and (max-width: 889px)
        margin-top: 25px
      @media screen and (max-width: 760px)
        margin-top: vwm(41px)
        width: vwm(148px)
      &:hover
        background: #6933B0
        color: #FFF
      &--end
        width: 335px
        margin-top: 6px
        text-decoration: none
        @media screen and (max-width: 760px)
          margin-top: vwm(6px)
          width: vwm(220px)
          font-size: vwm(12px)
          height: vwm(35px)
      &--white
        width: 244px
        background: #6933B0
        color: #FFF
        margin-top: 21px
        @media screen and (max-width: 1200px)
          width: 160px
        @media screen and (max-width: 889px)
          margin-top: 17px
          width: 150px
        @media screen and (max-width: 760px)
          margin-top: vwm(25px)
          width: vwm(184px)
          height: vwm(36px)
          font-size: vwm(14px)
        &:hover
          background: #8654CC
      &--promo
        width: 280px
        background: #6933B0
        color: #FFF
        text-decoration: none
        margin-top: 30px
        
        @media screen and (max-width: 1200px)
          margin-top: 22px
          width: 214px
        @media screen and (max-width: 889px)
          font-size: 14px
          width: 185px
          height: 36px
        @media screen and (max-width: 760px)
          font-size: vwm(14px)
          width: vwm(185px)
        &:hover
          background: #8654CC
    &-inputs
      display: flex
      align-items: center
      justify-content: space-evenly
      input
        vertical-align: baseline
      @media screen and (max-width: 760px)
        display: inline-grid
        margin-top: vwm(19px)
    &-label
      color: #FFF
      font-size: 24px
      font-weight: 400
      line-height: 28.8px
    &-info
      margin-top: 40px
      width: 804px
      height: 333px
      border-radius: 20px
      background: #FFF
      padding-top: 30px
      padding-left: 21px
      padding-right: 21px
      @media screen and (max-width: 1200px)
        padding-left: 100px
        padding-right: 100px
        margin-top: 36px
        width: 624px
        height: 426px
      @media screen and (max-width: 889px)
        width: 578px
        height: 405px
        margin-top: 31px
      @media screen and (max-width: 760px)
        margin-top: vwm(25px)
        width: vwm(300px)
        height: vwm(357px)
        padding-left: vwm(16px)
        padding-right: vwm(16px)
        padding-top: vwm(14px)
      .gift__bottom-description
        @media screen and (max-width: 760px)
          line-height: 18px
      &--promo
        height: 301px
        @media screen and (max-width: 1200px)
          height: 266px
        @media screen and (max-width: 760px)
          height: vwm(230px)
          margin-top: vwm(25px)
          padding-left: vwm(16px)
          padding-right: vwm(16px)
          width: vwm(300px)
    &-description
      text-align: center
      color: #323232
      font-family: SB Sans Display
      font-size: 24px
      font-weight: 600
      line-height: 28.8px
      span
        font-family: Graphik LC
      @media screen and (max-width: 1200px)
        font-size: 21px
        line-height: 22.8px
        font-weight: 500
      @media screen and (max-width: 889px)
        font-size: 19px
        line-height: 30.8px
      @media screen and (max-width: 760px)
        font-size: vwm(12px)
        font-weight: 600
        line-height: vwm(14.4px) !important
        
    &-items
      display: flex
      justify-content: space-between
      margin-top: 30px
      @media screen and (max-width: 1200px)
        display: block
      input
        display: flex
        width: 246px
        height: 50px
        padding: 5px 5px 5px 20px
        align-items: center
        gap: 8px
        flex-shrink: 0
        border-radius: 24px
        border: 2px solid #8654CC
        outline: none
        font-size: 14px
        cursor: pointer
        @media screen and (max-width: 1200px)
          width: 100%
          margin-top: 12px
        @media screen and (max-width: 889px)
          height: 46px
        @media screen and (max-width: 760px)
          height: vwm(43px)
          margin-top: vwm(10px)
        &:placeholder
          color: rgba(50, 50, 50, 0.50)
          font-size: 14px
          font-weight: 400
          line-height: 24px
          letter-spacing: 0.266px
      &--promo
        justify-content: center
        input
          border: 2px dashed #8654CC
          width: 280px
          color: #6933B0
          text-align: center
          font-family: SB Sans Text
          font-size: 24px
          font-style: normal
          font-weight: 600
          line-height: 40px
          cursor: pointer
          @media screen and (max-width: 1200px)
            display: flex
            margin: auto
          @media screen and (max-width: 760px)
            width: 100%

</style>

<style>
/* стили радио-кнопок */
label {
  float: left;
}

.custom-radio [type="radio"]:checked,.custom-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-radio [type="radio"]:checked + .custom-radio__label,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label {
  position: relative;
  padding: 6px 0 4px 38px;
  cursor: pointer;
  display: inline-block;
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
}
@media screen and (max-width: 1200px) {
  .custom-radio [type="radio"]:checked + .custom-radio__label,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label {
    padding: 6px 0 4px 38px;
    font-size: 18px;
    line-height: 28.8px;
  }
}
@media screen and (max-width: 760px) {
  .custom-radio [type="radio"]:checked + .custom-radio__label,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label {
    padding: 6px 0 4px 38px;
    font-size: 16px;
    line-height: 28.8px;
    text-align: initial;
  }
  .custom-radio br {
    display: none !important;
  }
}

.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 21px;
  height: 21px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  background: #8654CC;
}
.custom-radio__label br {
  display: none;
}
@media screen and (max-width: 760px) {
  .custom-radio__label br {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .custom-radio [type="radio"]:not(:checked) + .custom-radio__label:before {
    width: 19px;
    height: 19px;
    top: 8px;
  }
}
@media screen and (max-width: 760px) {
  .custom-radio [type="radio"]:not(:checked) + .custom-radio__label:before {
    width: 13px;
    height: 13px;
    top: 10px;
    left: 11px;
  }
}

.custom-radio [type="radio"]:checked + .custom-radio__label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 21px;
  height: 21px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 100%;
  background: #8654CC;
}
@media screen and (max-width: 1200px) {
  .custom-radio [type="radio"]:checked + .custom-radio__label:before {
    width: 19px;
    height: 19px;
    top: 8px;
  }
}
@media screen and (max-width: 760px) {
  .custom-radio [type="radio"]:checked + .custom-radio__label:before {
    width: 13px;
    height: 13px;
    top: 10px;
    left: 11px;
  }
}
.custom-radio [type="radio"]:checked + .custom-radio__label:after,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:after {
  content: '';
  width: 11px;
  height: 11px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 13px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
@media screen and (max-width: 1200px) {
  .custom-radio [type="radio"]:checked + .custom-radio__label:after,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:after {
    top: 14px;
    left: 6px;
    width: 11px;
    height: 11px;
  }
}
@media screen and (max-width: 760px) {
  .custom-radio [type="radio"]:checked + .custom-radio__label:after,.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:after {
    top: 15px;
    left: 16px;
    width: 7px;
    height: 7px;
  }
}

.custom-radio [type="radio"]:not(:checked) + .custom-radio__label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.custom-radio [type="radio"]:checked + .custom-radio__label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* стили инпутов */

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  cursor: pointer;
  display: flex;
  white-space: pre;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox + label br {
  display: none;
}
@media screen and (max-width: 1200px) {
  .custom-checkbox + label br {
    display: block;
  }
}

.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #8654CC;
  border-radius: 4px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox:checked + label::before {
  border-radius: 4px;
  background: #8654CC;
  background-size: cover;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IlRpY2siPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTIuNDUyNSA0Ljg1OTYxQzEyLjM5MDggNC43OTg0OCAxMi4zMTc0IDQuNzQ5OTYgMTIuMjM2NCA0LjcxNjg1QzEyLjE1NTUgNC42ODM3MyAxMi4wNjg3IDQuNjY2NjkgMTEuOTgxIDQuNjY2NjlDMTEuODkzNCA0LjY2NjY5IDExLjgwNjUgNC42ODM3MyAxMS43MjU2IDQuNzE2ODVDMTEuNjQ0NyA0Ljc0OTk2IDExLjU3MTIgNC43OTg0OCAxMS41MDk1IDQuODU5NjFMNi41NjE4NyA5LjcyNTEzTDQuNDgzMiA3LjY3NzE3QzQuNDE5MSA3LjYxNjM2IDQuMzQzNDMgNy41Njg1NSA0LjI2MDUxIDcuNTM2NDZDNC4xNzc1OSA3LjUwNDM2IDQuMDg5MDUgNy40ODg2MyAzLjk5OTk0IDcuNDkwMTRDMy45MTA4MyA3LjQ5MTY1IDMuODIyODkgNy41MTAzOSAzLjc0MTE2IDcuNTQ1MjhDMy42NTk0MiA3LjU4MDE3IDMuNTg1NDggNy42MzA1MyAzLjUyMzU2IDcuNjkzNDhDMy40NjE2NCA3Ljc1NjQzIDMuNDEyOTUgNy44MzA3NSAzLjM4MDI3IDcuOTEyMThDMy4zNDc1OSA3Ljk5MzYxIDMuMzMxNTcgOC4wODA1NyAzLjMzMzExIDguMTY4MDhDMy4zMzQ2NSA4LjI1NTYgMy4zNTM3MyA4LjM0MTk2IDMuMzg5MjYgOC40MjIyM0MzLjQyNDc4IDguNTAyNSAzLjQ3NjA2IDguNTc1MTIgMy41NDAxNiA4LjYzNTkzTDYuMDkwMzUgMTEuMTQwNEM2LjE1MjA5IDExLjIwMTYgNi4yMjU1NCAxMS4yNTAxIDYuMzA2NDcgMTEuMjgzMkM2LjM4NzQgMTEuMzE2MyA2LjQ3NDIgMTEuMzMzNCA2LjU2MTg3IDExLjMzMzRDNi42NDk1NCAxMS4zMzM0IDYuNzM2MzQgMTEuMzE2MyA2LjgxNzI3IDExLjI4MzJDNi44OTgyIDExLjI1MDEgNi45NzE2NSAxMS4yMDE2IDcuMDMzMzkgMTEuMTQwNEwxMi40NTI1IDUuODE4MzdDMTIuNTIgNS43NTcyOSAxMi41NzM4IDUuNjgzMTcgMTIuNjEwNSA1LjYwMDY2QzEyLjY0NzMgNS41MTgxNiAxMi42NjYzIDUuNDI5MDYgMTIuNjY2MyA1LjMzODk5QzEyLjY2NjMgNS4yNDg5MiAxMi42NDczIDUuMTU5ODIgMTIuNjEwNSA1LjA3NzMyQzEyLjU3MzggNC45OTQ4MSAxMi41MiA0LjkyMDY5IDEyLjQ1MjUgNC44NTk2MVoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9zdmc+Cg==");
}
.custom-checkbox:not(:disabled):active + label::before {
  background-color: #8654CC;
}

</style>