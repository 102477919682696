<template>
	<div class="container">
		<div class="footter">
			<div class="footter__top">
				<div class="footter__top-logo">
					<a href="https://megamarket.ru/" target="_blank"><img src="../assets/img/megamarket.svg" alt=""></a>
					<a href="https://www.pik.ru/?utm_source=megamarket&utm_medium=display&utm_content=logo" target="_blank"><img src="../assets/img/Pikbottom.svg" @click="sendEvent('logo_footer_pik')" alt=""></a>
				</div>
				<div class="footter__top-links">
					<!-- <a href=""><img src="../assets/img/youtube.svg" alt=""></a> -->
					<a href="javascript:void(0)"  data-url="https://pik.megamarket.ru" data-title="Квартиры ПИК на Мегамаркете" @click="share('vk'); sendEvent('share_vk')" data-image="https://pik.megamarket.ru/share.png" data-type="vk" ref="vk"><img src="../assets/img/vk.svg" alt=""></a>
					<a href="javascript:void(0)"  data-url="https://pik.megamarket.ru" data-title="Квартиры ПИК на Мегамаркете" @click="share('ok'); sendEvent('share_ok')" data-image="https://pik.megamarket.ru/share.png" data-type="ok" ref="ok"><img src="../assets/img/ok.svg" alt=""></a>
					<a href="javascript:void(0)"  data-url="https://pik.megamarket.ru" data-title="Квартиры ПИК на Мегамаркете" @click="share('telegram'); sendEvent('share_tel')" data-image="https://pik.megamarket.ru/share.png" data-type="telegram" ref="telegram"><img src="../assets/img/tg.svg" alt=""></a>
				</div>

			</div>
			<div class="footter__text">
				<p>Партнер ПАО «ПИК-специализированный застройщик». ОГРН 1027739137084. г. Москва, 123242, ул. Баррикадная, д. 19, стр. 1. Проектные декларации на наш.дом.рф. Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ<br><br>Реклама | ПАО «ПИК-специализированный застройщик» | erid: 2sHEdXsTbhL3oDT7xLYXdFWyyFs58dFSuid7akqpwtqsBDH1mEWM2YY9</p> <br/>
				<p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
			</div>
			<div class="footter__mobile">
				<p>Партнер ПАО «ПИК-специализированный застройщик». ОГРН 1027739137084. г. Москва, 123242, ул. Баррикадная, д. 19, стр. 1. Проектные декларации на наш.дом.рф. Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ<br><br>Реклама | ПАО «ПИК-специализированный застройщик» | erid: 2sHEdXsTbhL3oDT7xLYXdFWyyFs58dFSuid7akqpwtqsBDH1mEWM2YY9</p> <br/>
				<p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
			</div>
		</div>
	</div>
</template>

<script>
  import JSShare from "js-share";
  export default {
	name: "Footer",
	methods: {
		share(soc) {
			JSShare.go(this.$refs[soc]);
		},
	}
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.footter
	margin-top: 53px
	padding-bottom: 53px
	@media screen and (max-width: 4920px) and (min-width: 1728px)
		margin-top: 59px
		padding-bottom: 59px
	@media screen and (max-width: 760px)
		margin-top: vwm(53px)
		padding-bottom: vwm(53px)
	&__top
		display: flex
		align-items: center
		@media screen and (max-width: 760px)
			display: block
		&-logo
			display: flex
			a
				cursor: pointer
				&:first-child
					display: flex
					align-items: flex-end
					padding-right: 11px
					border-right: 1px solid #CACACA
					@media screen and (max-width: 4920px) and (min-width: 1730px)
					padding-right: 12px
					border-right: 1px solid #CACACA
					@media screen and (max-width: 760px)
						padding-right: vwm(11px)
					img
						width: 178px
						height: 26px
						@media screen and (max-width: 4920px) and (min-width: 1730px)
							width: 198px
							height: 28px
						@media screen and (max-width: 760px)
							width: vwm(175px)
							height: vwm(23px)

				&:last-child
					margin-left: 10px
					@media screen and (max-width: 4920px) and (min-width: 1730px)
						margin-left: 13px
					@media screen and (max-width: 760px)
						margin-left: vwm(-45px)
					img
						width: 55px
						height: 19px
						@media screen and (max-width: 4920px) and (min-width: 1730px)
							width: 60px
							height: 28px
						@media screen and (max-width: 760px)
							width: vwm(175px)
							height: vwm(20px)

		&-links
			display: flex
			align-items: flex-end
			margin-left: auto
			@media screen and (max-width: 760px)
				margin-top: vwm(15px)
				align-items: center
			a
				cursor: pointer
				margin-right: 12px

				@media screen and (max-width: 4920px) and (min-width: 1728px)
					margin-right: 13px
				@media screen and (max-width: 760px)
					margin-right: vwm(12px)
				img
					width: 32px
					height: 32px
					@media screen and (max-width: 4920px) and (min-width: 1728px)
						width: 33px
						height: 33px
					@media screen and (max-width: 760px)
						width: vwm(29px)
						height: vwm(29px)
				&:last-child
					margin-right: 0
					
	&__text
		margin-top: 25px
		@media screen and (max-width: 4920px) and (min-width: 1728px)
			margin-top: 28px
		@media screen and (max-width: 760px)
			display: none
		p
			color: #000
			font-size: 14px
			font-family: Graphik LC
			font-weight: 400
			line-height: 19px
			letter-spacing: -0.28px
			opacity: 0.5
			a
				cursor: pointer
				color: #000
			@media screen and (max-width: 4920px) and (min-width: 1728px)
				font-size: 16px
				line-height: 22px
	&__mobile
		display: none
		p
			color: #000
			font-size: vwm(10px)
			font-family: Graphik LC
			font-weight: 400
			line-height: vwm(14px)
			letter-spacing: vwm(-0.2px)
			opacity: 0.5
			a
				cursor: pointer
				color: #000
		@media screen and (max-width: 760px)
			display: block
			margin-top: vwm(16px)
</style>